import {
  Box,
  Button, Flex, Image, Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router';

import Logo from 'assets/sterling-sound-light-logo.svg';
import useIsSpecificRoute from 'hooks/useIsSpecificRoute';

export default function SignOptionsPage(): JSX.Element {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const { isBookingRoute } = useIsSpecificRoute();

  // if (isBookingRoute && !isLoggedIn) {
  //   return (
  //     <Navigate to={searchParams.toString() !== ''
  //       ? `/booking/sign-options?${searchParams.toString()}` : '/booking/sign-options'}
  //     />
  return (
    <Flex direction='column' alignItems='center'>
      <>
        <Image src={Logo} alt='logo' width='300px' />
        <Flex direction='column' width='372px' gap='35px' mt='65px'>
          <Text textAlign='center' fontSize='20px'>Do you have an account with us?</Text>
          <Button onClick={() => navigate(searchParams.toString() !== ''
            ? `/booking/sign-up?${searchParams.toString()}` : '/booking/sign-up')}
          >
            No, register account
          </Button>
          <Flex alignItems='center' justifyContent='center' my={4} mt='15' mb='15'>
            <Box borderBottom='1px solid white' width='110px' />
            <Text mx={5}>Or</Text>
            <Box borderBottom='1px solid white' width='110px' />
          </Flex>
          <Button
            onClick={() => navigate(searchParams.toString() !== ''
              ? `/login?redirectTo=/booking/new&${searchParams.toString()}` : '/login?redirectTo=/booking/new')}
            variant='back'
          >
            Yes, sign in
          </Button>
        </Flex>
      </>
    </Flex>
  );
}
