import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { v4 } from 'uuid';

import { studioServerApi } from 'api/studioServerApi';
import { Contact } from 'api/studioServerTypes';
import useUserContext from 'hooks/useUserContext';
import { ISelectedContactRoles } from 'pages/booking/sessions/project/components/RoleSelection';

export interface IAddNewContact {
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone?: string;
}

interface ICreateProjectContact {
  addNewContact: (newContact: IAddNewContact) => Promise<Contact>;
  addProjectContact: (contactIdentifier: string) => Promise<void>;
  addRolesToContact: (contactIdentifier: string, roles: ISelectedContactRoles) => Promise<void>;
}

export default function useCreateProjectContact({ projectIdentifier }: { projectIdentifier: string }): ICreateProjectContact {
  const queryClient = useQueryClient();
  const { user } = useUserContext();

  const addNewContact = useCallback(async (newContact: IAddNewContact): Promise<Contact> => {
    const newContactIdentifier = v4();
    const now = new Date().toISOString();
    const postNewContactResponse = await studioServerApi.POST('/api/contacts', {
      body: {
        Contact: {
          ContactIdentifier: newContactIdentifier,
          Email: newContact.email,
          FirstName: newContact.firstName,
          LastName: newContact.lastName,
          MobilePhone: newContact.mobilePhone,
        },
        ContactAddresses: [{
          ContactAddressIdentifier: v4(),
          ContactIdentifier: newContactIdentifier,
          AddedBy: user?.EmailAddress,
          DateAdded: now,
          DateModified: now,
        }],
      },
    });
    const contactNumber = postNewContactResponse.data;
    const getContactResponse = await studioServerApi.GET('/api/contacts', {
      params: {
        query: {
          includeDoNotUse: false,
          contactNumber,
        },
      },
    });

    const contact = getContactResponse.data!.find((c) => c.ContactNumber === contactNumber)!;

    return contact;
  }, [user?.EmailAddress]);

  const addProjectContact = useCallback(async (contactIdentifier: string): Promise<void> => {
    await studioServerApi.POST('/api/projects/{projectIdentifier}/project-contacts', {
      params: {
        path: {
          projectIdentifier,
        },
      },
      body: {
        ProjectContactIdentifier: v4(),
        ContactIdentifier: contactIdentifier!,
        ProjectIdentifier: projectIdentifier,
      },
    });
  }, [projectIdentifier]);

  const addRolesToContact = useCallback(async (contactIdentifier: string, roles: ISelectedContactRoles): Promise<void> => {
    if (roles.isUploader) {
      await studioServerApi.POST('/api/projects/{projectIdentifier}/transfer-requests', {
        params: {
          path: {
            projectIdentifier,
          },
        },
        body: {
          ContactIdentifier: contactIdentifier,
          DownloadRequiresMfa: false,
        },
      });
    }

    if (roles.isReceiver) {
      await studioServerApi.POST('/api/work-orders/add-receiver-contact', {
        body: {
          ContactIdentifier: contactIdentifier,
          ProjectIdentifier: projectIdentifier,
        },
      });
    }

    await queryClient.invalidateQueries({ queryKey: ['/api/projects/{projectIdentifier}/contacts', projectIdentifier] });
  }, [projectIdentifier, queryClient]);

  return {
    addNewContact,
    addProjectContact,
    addRolesToContact,
  };
}
