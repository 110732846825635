export function PasswordSettingsIcon(): JSX.Element {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        // eslint-disable-next-line max-len
        d='M7.66667 10.5833V12.25M3.5 6.85733C3.89285 6.83333 4.37716 6.83333 5 6.83333H10.3333C10.9562 6.83333 11.4405 6.83333 11.8333 6.85733M3.5 6.85733C3.00973 6.88725 2.66191 6.95458 2.36503 7.10583C1.89462 7.3455 1.51217 7.72792 1.27248 8.19833C1 8.73317 1 9.43317 1 10.8333V12C1 13.4002 1 14.1002 1.27248 14.635C1.51217 15.1054 1.89462 15.4878 2.36503 15.7275C2.8998 16 3.59987 16 5 16H10.3333C11.7335 16 12.4335 16 12.9683 15.7275C13.4387 15.4878 13.8212 15.1054 14.0608 14.635C14.3333 14.1002 14.3333 13.4002 14.3333 12V10.8333C14.3333 9.43317 14.3333 8.73317 14.0608 8.19833C13.8212 7.72792 13.4387 7.3455 12.9683 7.10583C12.6714 6.95458 12.3236 6.88725 11.8333 6.85733M3.5 6.85733V5.16667C3.5 2.86548 5.36548 1 7.66667 1C9.96783 1 11.8333 2.86548 11.8333 5.16667V6.85733'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>

  );
}
