/* eslint-disable @typescript-eslint/naming-convention */
import { createStandaloneToast } from '@chakra-ui/react';
import axios, { InternalAxiosRequestConfig } from 'axios';

import { getAuthHeader, signOut } from './auth';
import { Sentry } from 'configureSentry';
import { env } from 'env';

interface ErrorData {
  [key: string]: any;
}

const { toast } = createStandaloneToast({
  defaultOptions: {
    status: 'error',
    position: 'top',
    isClosable: true,
  },
});

export async function authInterceptor(config: InternalAxiosRequestConfig<any>): Promise<any> {
  config.headers.Authorization = await getAuthHeader();
  config.headers['X-APP-VERSION'] = env.REACT_APP_VERSION;
  config.headers['X-APP-SHA'] = env.REACT_APP_SHA;
  return config;
}

axios.interceptors.request.use(authInterceptor, (error) => Promise.reject(error));

axios.interceptors.response.use((config) => config, async (error) => {
  if (!axios.isAxiosError(error)) {
    return await Promise.reject(error);
  }
  if (error.code === 'ERR_CANCELED') return;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: ErrorData | undefined = error.response?.data;

  Sentry.addBreadcrumb({
    category: 'axios',
    message: error.message,
    data: {
      response: data || 'No response data available',
    },
    level: 'error',
  });

  if (error.response?.data?.message?.startsWith('OTPA-')) {
    await signOut();
  }

  if (error.config?.method === 'get' || error.config?.silentErrors) return await Promise.reject(error); // should be handled by AsyncWrapper
  if (data) {
    const status = getAxiosErrorStatus(error);
    const message: string = data.message || '';
    void toast({
      title: status,
      description: message,
    });
  } else {
    void toast({
      title: 'Network error',
      description: error.message,
    });
  }
  return await Promise.reject(error);
});

export function getAxiosErrorStatus(error: any): string {
  if (axios.isAxiosError(error)) {
    if (error.response?.status) {
      return `${error.response.status}`;
    }

    return `${error.code || 'UNKNOWN'}: ${error.message}`;
  }
  return 'Unknown network error';
}
