import humanFormat from 'human-format';

function appendToUint8Array(original: Uint8Array, newChunk: Uint8Array): Uint8Array {
  const combined = new Uint8Array(original.length + newChunk.length);
  combined.set(original);
  combined.set(newChunk, original.length);
  return combined;
}

export function createBuffer(): TransformStream<Uint8Array, Uint8Array> {
  const bufferSize = humanFormat.parse('10 MB');
  let buffer = new Uint8Array();

  const bufferPipe = new TransformStream<Uint8Array, Uint8Array>({

    transform(chunk, controller) {
      buffer = appendToUint8Array(buffer, chunk);

      if (buffer.length >= bufferSize) {
        console.trace(`Flushing buffer to disk: ${humanFormat(buffer.length)}...`);
        controller.enqueue(buffer);
        buffer = new Uint8Array();
      }
    },

    flush(controller) {
      if (buffer.length) {
        controller.enqueue(buffer);
      }
    },
  });

  return bufferPipe;
}
