import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  field: {
    background: 'gray.800',
  },
  Option: {
    baseStyle: {
      backgroundColor: 'gray.800',
      _hover: {
        backgroundColor: 'gray.600',
      },
      color: 'gray.400',
    },
  },
});

export const selectTheme = defineMultiStyleConfig({ baseStyle });
