import { RepeatIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Img, Tooltip,
  useToast,
} from '@chakra-ui/react';
import { type Row, createColumnHelper } from '@tanstack/react-table';
import axios from 'axios';
import {
  Case, Default, Else, If, Switch, Then,
} from 'react-if';
import { useNavigate } from 'react-router-dom';

import ArchiveIcon from 'assets/icons/archive.svg';
import DownloadIcon from 'assets/icons/download.svg';
import RestoreIcon from 'assets/icons/restore.svg';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import ChromeDownloadModal from 'components/ChromeDownloadModal';
import DateComponent from 'components/DateComponent';
import { ChromeSecurityNoticeModal } from 'components/google-context-modal/ChromeSecurityNoticeModal';
import ProjectName from 'components/ProjectName';
import SmsVerifyModal from 'components/SmsVerifyModal';
import {
  ArtistHeader, DateRestoredHeader, ProjectHeader, RestoredByHeader, SizeHeader, StatusHeader, TeamHeader,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import { useAzureDownload } from 'hooks/useAzureDownload';
import useUserContext from 'hooks/useUserContext';
import {
  ArchivedTransfersCTRow,
  Files,
  TransferRestoreRequests,
} from 'types/api';
import { filesSizeTags } from 'utils/formatter';
import { getArchivesStatus } from 'utils/status';

export type ArchivesTableColumns = {
  project?: boolean,
  artist?: boolean,
  size?: boolean,
  dateRestored?: boolean,
  restoredBy?: boolean,
  status?: boolean,
};

export type ArchivesTableProps = {
  path: string,
  invisibleColumns?: ArchivesTableColumns,
  emptyTableState: {
    message: string,
    icon: string,
  },
};

export default function ArchivesTable({
  path,
  invisibleColumns,
  emptyTableState,
}: ArchivesTableProps): JSX.Element {
  const toast = useToast();
  const navigate = useNavigate();

  const { user } = useUserContext();

  const {
    downloadBlobs, smsModalProps, chromeSecurityNotificationProps, chromeDownloadModalProps,
  } = useAzureDownload();
  async function downloadBlob(id: string, artist: string | undefined): Promise<void> {
    const res = await axios.get<Files[]>(`/file-api/transfers/${id}/file-list`);
    await downloadBlobs({ blobs: res.data, artist });
  }

  async function restoreArchive(archiveId: string): Promise<void> {
    const restoringArchiveRequest = await axios.post<TransferRestoreRequests>(`/file-api/archives/${archiveId}/restore`);
    toast({
      title: restoringArchiveRequest.data.Fulfilled ? 'Archive is already restored' : 'Archive is restoring',
      status: restoringArchiveRequest.data.Fulfilled ? 'warning' : 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  const columnHelper = createColumnHelper<ArchivedTransfersCTRow>();
  const archiveTableColumns = [
    columnHelper.accessor('project', {
      cell: (info) => ProjectName(info.getValue()),
      header: ProjectHeader,

    }),
    columnHelper.accessor('artist', {
      cell: (info) => info.getValue(),
      header: ArtistHeader,
    }),
    columnHelper.display({
      id: 'size',
      header: SizeHeader,
      cell: ({ row }) => filesSizeTags(row.original.filesSize, row.original.filesCount),
    }),
    columnHelper.accessor('dateRestored', {
      cell: ({ row }) => (() => (
        <DateComponent date={row.original.dateRestored || undefined} />
      ))(),
      header: DateRestoredHeader,
    }),
    columnHelper.accessor('restoredBy', {
      header: RestoredByHeader,
      cell: ({ row }) => (() => (
        <If condition={row.original.restoredBy !== 'N/A'}>
          <Then>
            <Tooltip label={row.original.restoredBy}>
              <Avatar size='sm' name={row.original.restoredBy || undefined} />
            </Tooltip>
          </Then>
          <Else>
            -
          </Else>
        </If>

      ))(),
    }),
    columnHelper.display({
      id: 'status',
      header: StatusHeader,
      cell: ({ row }) => {
        const archiveStatus = getArchivesStatus(row.original.restoring, row.original.dateRestored);
        return (() => (
          <Switch>
            <Case condition={archiveStatus === 'Restored'}>
              <Tooltip label={archiveStatus} placement='auto'>
                <Img w='20px' src={DownloadIcon} />
              </Tooltip>
            </Case>
            <Case condition={archiveStatus === 'Restoring'}>
              <Tooltip label={archiveStatus} placement='auto'>
                <Img w='20px' src={RestoreIcon} />
              </Tooltip>
            </Case>
            <Default>
              <Tooltip label={archiveStatus} placement='auto'>
                <Img w='20px' src={ArchiveIcon} />
              </Tooltip>
            </Default>
          </Switch>
        ))();
      },
    }),
  ];

  function onRowClick(row: Row<ArchivedTransfersCTRow>): void {
    navigate(`/archives/${row.original.transferId}`);
  }

  return (
    <>
      <SmsVerifyModal {...smsModalProps} />
      <ChromeSecurityNoticeModal {...chromeSecurityNotificationProps} />
      <ChromeDownloadModal {...chromeDownloadModalProps} />
      <StudiumTable
        apiPath={path}
        columns={archiveTableColumns}
        invisibleColumns={invisibleColumns}
        handleRowClick={onRowClick}
        stateKey='state'
        emptyTableState={emptyTableState}
        queryKeys={['archive-table']}
      />
    </>
  );
}
