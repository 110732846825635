import {
  Button, Input, InputGroup, InputLeftAddon,
} from '@chakra-ui/react';
import { ControllerFieldState, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { CountrySelector, usePhoneInput } from 'react-international-phone';

import ErrorDisplay from 'components/error/ErrorDisplay';
import 'react-international-phone/style.css';
import { useGeoInfo } from 'contexts/GeoInfoProvider';

export default function PhoneInput<T extends FieldValues>({ field, fieldState }: {
  field: ControllerRenderProps<T, any>;
  fieldState: ControllerFieldState;
}): JSX.Element {
  const { userCountryCode } = useGeoInfo();

  const phoneInput = usePhoneInput({
    defaultCountry: userCountryCode?.toLowerCase() || 'us',
    value: field.value,
    onChange: (data) => {
      field.onChange(data.phone);
    },
    forceDialCode: true,
  });

  return (
    <InputGroup sx={{ zIndex: 1000 }}>
      <CountrySelector
        selectedCountry={phoneInput.country.iso2}
        onSelect={(country) => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button {...rootProps} width='60px' px='4px' mr='8px' height='54' color='white' border='1px solid #A0AEC0' backgroundColor='transparent' _hover='none'>
            {children}
          </Button>
        )}
      />
      <Input
        {...field}
        placeholder='Phone number'
        type='tel'
        color='primary'
        value={phoneInput.inputValue}
        onChange={phoneInput.handlePhoneValueChange}
        width='100%'
        ref={phoneInput.inputRef}
        isInvalid={!!fieldState.error}
      />
      <ErrorDisplay error={fieldState.error} />
    </InputGroup>
  );
}
