import {
  Avatar, Box, BoxProps, Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { When } from 'react-if';

export interface ProfileProps extends BoxProps { displayName?: string | null, email?: string | null, avatarSrc?: string | null | Uint8Array }

export function Profile({
  displayName, email, avatarSrc, ...rest
}: ProfileProps): JSX.Element {
  const imageSrc = avatarSrc?.constructor === Uint8Array ? `data:image/png;base64, ${avatarSrc}` : avatarSrc;

  const [isLessThan700] = useMediaQuery('(max-width: 700px)');

  return (
    <Box
      display='flex'
      flexDir='row'
      justifyContent='space-between'
      alignItems='center'
      width='100%'
      paddingRight='9px'
      paddingLeft='23px'
      {...rest}
    >
      <When condition={!isLessThan700}>
        <Box display='flex' fontWeight='500' flexDir='column' flexGrow={1} paddingRight='15px'>
          <Text alignSelf='flex-end' fontSize='15px' lineHeight='18px'>{displayName}</Text>
          <Text
            alignSelf='flex-end'
            as='p'
            fontSize='12px'
            lineHeight='14px'
            color='gray.500'
            display={['none', 'none', 'none', 'inline-block']}
          >
            {email}
          </Text>
        </Box>
      </When>
      <Avatar name={displayName ?? undefined} src={imageSrc ? `${imageSrc}` : ''} boxSize='46px' />
    </Box>
  );
}
