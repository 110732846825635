import { ParsedPath } from 'path';

import { add, differenceInDays } from 'date-fns';

import { getDate } from './formatter';
import { FileMixType, FileReviewStatus, Files } from 'types/api';

export interface FileRow {
  type: string;
  subRows: FileRow[]
  FileId: number,
  FileSize: string,
  FileCount: number | string,
  FileChannels: number | undefined,
  BitsPerSample: number | undefined,
  AudioFormatId: number | undefined,
  SampleRate: number | undefined,
  Name: string
  DateCreated: Date | undefined,
  BlobDeleted: boolean | undefined,
}

interface WithBlobDeletedAndDateCreated {
  BlobDeleted: boolean;
  DateCreated: Date | string;
}

export const fileReviewStatusLabels: Record<FileReviewStatus, string> = {
  [FileReviewStatus.APPROVED]: 'Approved',
  [FileReviewStatus.REJECTED]: 'Rejected',
  [FileReviewStatus.PENDING]: 'Pending Approval',
};

export const fileMixStatusLabels: Record<FileMixType, string> = {
  [FileMixType.MAIN]: 'Main',
  [FileMixType.ALTERNATE]: 'Alternate',
  [FileMixType.TV_TRACK]: 'TV Track',
};

export function getFileIds(fileRow: FileRow): number[] {
  const stack: FileRow[] = [];
  const ids: number[] = [];

  stack.push(fileRow);

  while (stack.length > 0) {
    const currentRow = stack.pop();

    if (currentRow === undefined) break;

    if (currentRow.type === 'folder') {
      for (const row of currentRow.subRows) {
        stack.push(row);
      }
    } else {
      ids.push(currentRow.FileId);
    }
  }

  return ids;
}

export function getFileResolution(row: Partial<Files>, returnDash = false): string | undefined {
  const { BitsPerSample, SampleRate } = row;

  if (!BitsPerSample || !SampleRate) return returnDash ? '-' : undefined;
  return `${SampleRate! / 1000}/${BitsPerSample}`;
}

export async function getDirHandle(relativePath: string, rootDirHandle: FileSystemDirectoryHandle): Promise<FileSystemDirectoryHandle> {
  const subDirs = relativePath.split('/').filter((value) => !!value);
  let dirHandle = rootDirHandle;
  for (const segment of subDirs) {
    const sanitizedSegmentName = sanitizeFileName(segment);
    dirHandle = await dirHandle.getDirectoryHandle(sanitizedSegmentName, { create: true });
  }

  return dirHandle;
}

// https://github.com/parshap/node-sanitize-filename/blob/master/index.js
const illegalRe = /[/?<>\\:*|"~]/g;
// eslint-disable-next-line no-control-regex
const controlRe = /[\x00-\x1f\x80-\x9f]/g;
const reservedRe = /^\.+$/;
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
const windowsTrailingRe = /[.\s]+$/;
const startRe = /^[\s]+/;

const fatRe = /\^/;

export function sanitizeFileName(filename: string): string {
  const sanitized = filename
    .replace(illegalRe, '')
    .replace(controlRe, '')
    .replace(reservedRe, '')
    .replace(windowsReservedRe, '')
    .replace(windowsTrailingRe, '')
    .replace(startRe, '')
    .replace(fatRe, '');

  return sanitized.slice(0, 255); // windows generally has a max of 255 chars for filenames
}

function computeFolderSize(node: FileRow): [number, number] {
  let size = 0;
  let count = 0;

  for (const subRow of node.subRows) {
    if (subRow.type === 'file') {
      size += Number(subRow.FileSize);
      count += 1;
    } else {
      const [subSize, subCount] = computeFolderSize(subRow);
      size += subSize;
      count += subCount;
    }
  }

  node.FileSize = size.toString();
  node.FileCount = count;

  return [size, count];
}

export const isExpired = ({ BlobDeleted, DateCreated }: WithBlobDeletedAndDateCreated, dateFormat?: string): { expired: boolean, expiryDays: number, expiresAt: string } => {
  const expiryDaysInSeconds = 60 * 24 * 60 * 60; // 60 days
  const expiryDate = add(new Date(DateCreated).getTime(), { seconds: expiryDaysInSeconds });

  if (BlobDeleted || !DateCreated) {
    return {
      expired: true,
      expiryDays: 0,
      expiresAt: expiryDate.toISOString(),
    };
  }

  const daysLeft = differenceInDays(expiryDate, new Date());
  return {
    expired: daysLeft < 0,
    expiryDays: daysLeft,
    expiresAt: expiryDate.toISOString(),
  };
};

export function formatFilesToRows(files: Files[], rootFolderName?: string): FileRow[] {
  const roots: Record<string, FileRow> = {};
  const rootKeys: string[] = [];

  for (const file of files) {
    const relativePath = `${file.RelativePath}/${file.FileName}`;
    const parts = relativePath.split('/');
    let parent: FileRow | null = null;
    let path = '';

    for (let i = 0; i < parts.length; i += 1) {
      const part = parts[i];
      if (part === '') continue;
      path += `/${part}`;

      let node = roots[path];

      if (!node) {
        const type = i === parts.length - 1 ? 'file' : 'folder';
        const name = i === parts.length - 1 ? file.FileName : part;
        // eslint-disable-next-line no-multi-assign
        node = roots[path] = createNode({ type, name });

        if (!parent) {
          rootKeys.push(path);
        } else {
          parent.subRows.push(node);
        }
      }

      if (node.type === 'file') {
        node.FileSize += Number(file.FileSize);
        node.FileCount = '-';
        node.FileId = file.FileId;
        node.FileChannels = file.NumberOfChannels ?? undefined;
        node.BitsPerSample = file.BitsPerSample ?? undefined;
        node.SampleRate = file.SampleRate ?? undefined;
        node.DateCreated = file.DateCreated;
        node.BlobDeleted = file.BlobDeleted;
      }

      parent = node;
    }
  }

  const rootNodes = rootKeys.map((key) => roots[key]);

  if (rootNodes.length === 1 && rootNodes[0].type === 'file') {
    const rootNode: FileRow = createNode({
      type: 'folder', name: rootFolderName ?? '/', size: rootNodes[0].FileSize, count: 1,
    });
    rootNode.subRows = rootNodes;
    return [rootNode];
  }

  const root = rootNodes.length !== 1 ? createRootNode(rootNodes, rootFolderName) : rootNodes;

  for (const node of root) {
    computeFolderSize(node);
  }

  return root;
}

function createRootNode(rootNodes: FileRow[], folderName?: string): FileRow[] {
  const rootNode: FileRow = createNode({ type: 'folder', name: folderName });
  rootNode.subRows = rootNodes;
  return [rootNode];
}

function createNode({
  type, name, size, count,
}: { type?: string, name?: string, size?: string, count?: number }): FileRow {
  const node: FileRow = {
    type: type || 'folder',
    Name: name || 'root',
    subRows: [],
    FileId: 0,
    FileSize: size ?? '0',
    FileCount: count ?? 0,
    FileChannels: undefined,
    BitsPerSample: undefined,
    AudioFormatId: undefined,
    SampleRate: undefined,
    DateCreated: undefined,
    BlobDeleted: undefined,
  };

  return node;
}

export function getParentDirectoryName(path: ParsedPath): string {
  const { dir } = path;
  const split = dir.split('/'); // every path parsed as posix
  if (split.length <= 1) return '/';
  return split.pop()!;
}
// /folder/thing.wav => 'folder'
// /thing.wav => '/'
export function getRootDirectoryName(path: ParsedPath): string {
  const { dir, base } = path;
  if (base && !dir) return '/'; // file in root
  const split = dir.split('/'); // every path parsed as posix
  if (split.length <= 1) return '/';
  return split[1];
}
