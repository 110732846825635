import { Flex, Text, chakra } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ISheetProps {
  children: ReactNode,
  title: string,
  subtitle?: string,
}

function Sheet({
  children, title, subtitle,
}: ISheetProps): JSX.Element {
  return (
    <Flex
      direction='column'
      my='18px'
      borderColor='gray.200'
      borderRadius='3xl'
      px={[0, '55px']}
      py={[0, '80px']}
      borderWidth={[0, '1px']}
    >
      <Text textStyle='heading3' as='h3' mt='-100px' position={['relative', 'absolute']} align='center'>{title}</Text>
      <Text textStyle='heading4' position={['relative', 'absolute']} align='center' mt={['20px', '0px']}>{subtitle}</Text>
      {children}
    </Flex>
  );
}

export default chakra(Sheet);
