import { startCase } from 'lodash';

export const EMPTY_FILE_HASH = 'd41d8cd98f00b204e9800998ecf8427e';

export const Versions = {
  main: 'Main',
  alternate: 'Alternate',
  reference_mix: 'Reference Mix',
  recall: 'Recall',
  instrumental: 'Instrumental',
  acapella: 'Acapella',
  clean: 'Clean',
  tv_track: 'TV Track',
  radio_edit: 'Radio Edit',
  extended: 'Extended',
  remix: 'Remix',
};

export const versionOptions = Object.keys(Versions).map((key) => ({
  value: key,
  label: (Versions as Record<string, string>)[key],
}));

export type VersionKeys = keyof typeof Versions;

export function getVersionDisplay(row: { original: { Version: VersionKeys | null, MetadataHash: string | null } }): string {
  if (row.original.MetadataHash === EMPTY_FILE_HASH) return '-';
  if (!row.original.Version) return 'Main';
  return Versions[row.original.Version] || startCase(row.original.Version.replaceAll('_', ' '));
}
