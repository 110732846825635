import {
  Flex,
  Img,
  Table,
  Tbody,
  Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import {
  ColumnDef,
  ExpandedState,
  Getter, Row, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, useReactTable,
} from '@tanstack/react-table';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { When } from 'react-if';

import { generateFileMetadata } from './helpers/fileTags';
import FileListContext from '../FileListContext';
import audioFileIcon from 'assets/icons/audio-file.svg';
import fileDownIcon from 'assets/icons/chevron-down-icon.svg';
import fileRightIcon from 'assets/icons/chevron-right-icon.svg';
import folderIcon from 'assets/icons/folder.svg';
import fileIcon from 'assets/icons/non-audio-file.svg';
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox';
import { NameHeader } from 'components/table/headers';
import {
  FileRow,
  formatFilesToRows,
} from 'utils/file';

export function FileTreeView({
  setTreeSelectedFiles, rootFolderName,
}: { setTreeSelectedFiles: React.Dispatch<React.SetStateAction<number[]>>, rootFolderName: string }): JSX.Element {
  const { isReadOnly, fileList: files } = useContext(FileListContext);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const data = useMemo(
    () => formatFilesToRows(files, rootFolderName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  );

  const getCheckbox = (row: Row<FileRow>, getValue: Getter<any>): JSX.Element => (
    <div
      style={{ paddingLeft: `${row.depth * 2}rem`, display: 'flex', alignItems: 'center' }}
    >
      <When condition={!isReadOnly}>
        <IndeterminateCheckbox
          allChecked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          fileId={row.original.FileId}
          row={row.original}
          onChange={row.getToggleSelectedHandler()}
          setSelectedFiles={setTreeSelectedFiles}
        />
        {' '}
      </When>
      {/* eslint-disable-next-line jsx-control-statements/jsx-use-if-tag */}
      {row.getCanExpand() ? (
        <button
          onClick={row.getToggleExpandedHandler()}
          style={{ cursor: 'pointer', marginRight: '15px' }}
        // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
        > {row.getIsExpanded()
          ? (
            <Flex direction='row' alignItems='center'>
              <Img src={fileDownIcon} w='15px' h='15px' mx='15px' />
              <Img height='20px' width='26px' src={folderIcon} marginBottom='2px' />
            </Flex>
          )
          : (
            <Flex direction='row' alignItems='center'>
              <Img src={fileRightIcon} w='15px' h='15px' mx='15px' />
              <Img height='20px' width='26px' src={folderIcon} marginBottom='2px' />
            </Flex>
          )}
        </button>
      )
        : <Img height='22px' marginRight='16px' marginLeft='16px' width='30px' src={row.original.Name.includes('wav') ? audioFileIcon : fileIcon} />}
      {' '}
      <Text fontSize='14px' fontWeight='400' marginRight='15px'>
        {getValue()}
      </Text>
      <Text fontWeight='700' fontSize='14px'>
        {generateFileMetadata(row.original)}
      </Text>
    </div>
  );

  const columns = useMemo<ColumnDef<FileRow>[]>(
    () => [
      {
        id: 'SomeValue',
        columns: [
          {
            accessorKey: 'Name',
            cell: ({ row, getValue }) => getCheckbox(row, getValue),
            // eslint-disable-next-line react/prop-types
            footer: (props) => props.column.id,
            header: NameHeader,
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  return (
    <div className='p-2'>
      <div className='h-2' />
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup, index) => {
            if (index === 0) return;
            return (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {!header.isPlaceholder && (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                  </Th>
                ))}
              </Tr>
            );
          })}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div className='h-2' />
    </div>
  );
}
