import { startCase } from 'lodash';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { DevOverrideKeys, getOverride, setOverride } from './devOverrides';

export function DevToggle({ className, name, options }: { className?: string, name: DevOverrideKeys, options: string[] }): JSX.Element {
  const storedValue = getOverride(name);
  function setValue(e: ChangeEvent<HTMLSelectElement>): void {
    const { value } = e.target;
    setOverride(name, value);
  }

  return (
    <Container className={className}>
      <label>
        {startCase(name.replace('DEV__', ''))}
        :
      </label>
      <select onChange={setValue} value={storedValue || ''}>
        <option key='undefined' value=''>Default</option>
        {options.map((o) => <option key={o} value={o}>{o}</option>)}
      </select>
    </Container>
  );
}

const Container = styled.div`
  label {
    text-transform: capitalize;
    padding-right: 8px;
    font-size: 20px;
  }

  select {
    width: 200px;
    text-transform: capitalize;
  }

  width: 100%;
  margin-bottom: 24px;
`;
