import { Text, Tooltip } from '@chakra-ui/react';

import { Contacts } from 'types/api';

export function getContactDisplayName(contact?: { FirstName?: string | null, LastName?: string | null, Email?: string | null }, appendEmail = false): string {
  if (!contact) {
    return 'N/A';
  }
  let name: string | null;
  if (contact.FirstName && contact.LastName) {
    name = `${contact.FirstName} ${contact.LastName}`;
  } else if (contact.FirstName) {
    name = contact.FirstName;
  } else if (contact.LastName) {
    name = contact.LastName;
  } else {
    name = contact.Email!;
  }

  if (contact.Email && appendEmail && name !== contact.Email) {
    name += ` (${contact.Email})`;
  }

  return name!.trim();
}

export default function ContactDisplayName(contact: Pick<Contacts, 'FirstName' | 'LastName' | 'Email'> | undefined): JSX.Element {
  if (!contact) {
    return <>N/A</>;
  }
  let name: string | null;
  if (contact.FirstName && contact.LastName) {
    name = `${contact.FirstName} ${contact.LastName}`;
  } else if (contact.FirstName) {
    name = contact.FirstName;
  } else if (contact.LastName) {
    name = contact.LastName;
  } else {
    name = contact.Email;
  }

  return (
    <Tooltip label={contact.Email} placement='auto'>
      <Text fontSize='14px' fontWeight='500'>
        {name?.trim()}
      </Text>
    </Tooltip>
  );
}
