import {
  UseQueryOptions, UseQueryResult, useQuery,
} from '@tanstack/react-query';
import { FetchResponse } from 'openapi-fetch';

export type UseApiQueryOptions<T, O, Media extends `${string}/${string}`> = UseQueryOptions<FetchResponse<T, O, Media>, Error> & {
  queryFn: () => Promise<FetchResponse<T, O, Media>>;
};

export function useApiQuery<T, O, Media extends `${string}/${string}`>(
  { queryKey, queryFn, ...rest }: UseApiQueryOptions<T, O, Media>,
): {
    apiResult: FetchResponse<T, O, Media> | undefined,
    queryResult: UseQueryResult<FetchResponse<T, O, Media>>
  } {
  const query = useQuery<FetchResponse<T, O, Media>, Error>({
    queryKey,
    queryFn,
    throwOnError: true,
    ...rest,
  });

  return {
    apiResult: query.data,
    queryResult: query,
  };
}
