import { UseQueryResult, useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';

import { Pagination, PaginationParams } from 'components/table/Pagination';

interface InfiniteScrollingReturn<T> {
  queryData: UseQueryResult<AxiosResponse<T[], any>, Error>;
  PaginationControll: JSX.Element | null;
}

export function useTableData<T>({ path, disablePagination = false, queryKeys }: {
  path: string, disablePagination?: boolean, queryKeys: string[]
}): InfiniteScrollingReturn<T> {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<PaginationParams>({
    take: 40,
    skip: 0,
  });

  const q = useMemo(() => new URLSearchParams(search).get('search'), [search]);
  useEffect(() => {
    if (!q) return;
    navigate(`${pathname}?search=${q}`);
  }, [navigate, pathname, q]);
  useEffect(() => setPagination({ take: 40, skip: 0 }), [q]);

  let url = path;
  if (!disablePagination) {
    url += `?take=${pagination.take}&skip=${pagination.skip}`;
  }

  if (q) {
    url += `&q=${q}`;
  }

  const apiRes = useQuery({
    queryKey: [...queryKeys, url],
    queryFn: () => axios.get<T[]>(url),
  });

  const paginationControll = disablePagination
    ? null
    : Pagination({ pagination, setPagination, data: apiRes.data?.data || [] });

  return {
    queryData: apiRes,
    PaginationControll: paginationControll,
  };
}
