import {
  cleanEnv,
  str,
  url,
} from 'envalid';

export const env = cleanEnv({
  REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_SHA: process.env.REACT_APP_SHA,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
}, {
  REACT_APP_SHA: str({}),
  REACT_APP_VERSION: str({}),
  REACT_APP_FIREBASE_API_KEY: str({}),
  REACT_APP_GOOGLE_CLIENT_ID: str({}),
  REACT_APP_ENV: str<'local' | 'preview' | 'prod'>({}),
  REACT_APP_FIREBASE_AUTH_DOMAIN: str({}),
});

export const isProduction = env.REACT_APP_ENV === 'prod';

console.log('ENV', {
  REACT_APP_ENV: env.REACT_APP_ENV,
  REACT_APP_SHA: env.REACT_APP_SHA,
  REACT_APP_VERSION: env.REACT_APP_VERSION,
  REACT_APP_FIREBASE_AUTH_DOMAIN: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
