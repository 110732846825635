import {
  ReactNode, createContext, useContext, useMemo, useState,
} from 'react';
import { useParams } from 'react-router';

interface IContactInfo {
  email?: string;
  isUploader: boolean;
  isReceiver: boolean;
}
interface IAddContactState {
  projectIdentifier?: string;
  contactInfo?: IContactInfo;
  setContactInfo: React.Dispatch<React.SetStateAction<IContactInfo>>;
}

export const AddContactContext = createContext<IAddContactState>({
  projectIdentifier: undefined,
  contactInfo: undefined,
  setContactInfo: () => {},
});

export function AddContactProvider({ children }: { children: ReactNode, }): JSX.Element {
  const { projectIdentifier } = useParams<{ projectIdentifier: string }>();
  const [contactInfo, setContactInfo] = useState<IContactInfo>({
    email: undefined,
    isUploader: false,
    isReceiver: false,
  });

  const value = useMemo(
    () => ({
      projectIdentifier,
      contactInfo,
      setContactInfo,
    }),
    [projectIdentifier, contactInfo],
  );

  return (
    <AddContactContext.Provider value={value}>
      {children}
    </AddContactContext.Provider>
  );
}

export function useAddContact(): IAddContactState {
  return useContext(AddContactContext);
}
