import {
  Box, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';

import { studioServerApi } from 'api/studioServerApi';
import { DisplayRateGroupItem } from 'api/studioServerTypes';
import AsyncButton from 'components/form/AsyncButton';
import QuerySuspense from 'components/QuerySuspense';
import { useBookingContext } from 'contexts/booking/BookingContext';
import { useApiQuery } from 'hooks/useApiQuery';

export type DisplayLineItem = {
  description: string;
  rate: number;
  quantity: number;
  defaultQuantity: number;
  minimumQuantity: number;
  displayRateGroupItem: DisplayRateGroupItem;
};

export default function SelectLineItems(): JSX.Element {
  const { contact, resource } = useBookingContext();
  const { nextStep } = useWizard();
  const { setLineItems } = useBookingContext();

  const lineItemsQuery = useApiQuery({
    queryKey: ['/api/booking/line-items', contact?.Country!, resource?.ResourceIdentifier!],
    queryFn: () => studioServerApi.GET('/api/booking/line-items', {
      params: {
        query: {
          Country: contact?.Country!,
          ResourceIdentifier: resource?.ResourceIdentifier!,
        },
      },
    }),
  });

  const [rows, setRows] = useState<DisplayLineItem[]>([]);
  useEffect(() => {
    if (lineItemsQuery.apiResult?.data) {
      setRows(lineItemsQuery.apiResult?.data?.map((item) => ({
        description: item.DisplayRateGroupItem?.OnlinePartName!,
        rate: item.DisplayRateGroupItem?.Rate!,
        quantity: item.WholeNumberQuantity!,
        defaultQuantity: Math.floor(item.MinimumQuantity!),
        minimumQuantity: Math.floor(item.MinimumQuantity!),
        displayRateGroupItem: item.DisplayRateGroupItem!,
      })));
    }
  }, [lineItemsQuery.apiResult?.data]);

  const handleQuantityChange = (index: number, value: number) => {
    const newRows = [...rows];
    newRows[index].quantity = value;
    setRows(newRows);
  };

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const calculateSubtotal = () => USDollar.format(rows.reduce((acc, row) => acc + row.quantity * row.rate, 0));

  return (
    <Box>
      <Box>
        <QuerySuspense queries={[lineItemsQuery.queryResult]}>
          <TableContainer>

            <Table variant='booking' mt='10px'>
              <Thead
                backgroundColor='transparent'
              >
                <Tr>
                  <Th>
                    Qt
                  </Th>
                  <Th>Description
                  </Th>
                  <Th
                    _last={{
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                    }}
                  >Rate
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {rows.map((row, index) => (
                  <Tr
                    key={row.description}

                  >
                    <Td>
                      <NumberInput
                        defaultValue={row.defaultQuantity}
                        min={row.minimumQuantity}
                        minWidth='68px'
                        max={25}
                        onChange={(value) => handleQuantityChange(index, parseInt(value))}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </Td>
                    <Td>{row.description}
                    </Td>
                    <Td>{USDollar.format(row.rate)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Box borderBottom='1px solid #2D3748' width='100%' mt='15px' />
          <Box mb='25px' mt='25px'>
            <Flex justifyContent='space-between'>
              <Text fontSize='20px'>Estimated Subtotal:</Text>
              <Text fontSize='20px'>{calculateSubtotal()}</Text>
            </Flex>
          </Box>
          <AsyncButton
            width='100%'
            marginBottom='25px'
            onClick={async () => {
              setLineItems(rows.map((row) => ({
                Quantity: row.quantity,
                RateGroupItemIdentifier: row.displayRateGroupItem.RateGroupItemIdentifier,
                Description: row.description,
                Rate: row.rate,
              })));
              await nextStep();
            }}
          >
            Next
          </AsyncButton>
        </QuerySuspense>
      </Box>
    </Box>
  );
}
