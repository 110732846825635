import { FallbackProps } from 'react-error-boundary';

import ErrorPage from './ErrorPage';
import PageTemplate from '../page-template/PageTemplate';

export default function ErrorFallback({ error }: FallbackProps): JSX.Element {
  return (
    <PageTemplate>
      <ErrorPage error={error} />
    </PageTemplate>
  );
}
