import {
  Center, Divider, Flex, Image, Link, Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import SterlingLogoDark from 'assets/logos/sterling-fav.svg';
import { env } from 'env';

const year = new Date().getFullYear();

const footerTextStyles = {
  fontSize: '12px',
  lineHeight: '18px',
  fontWeight: '400',
};

export default function Footer(): JSX.Element {
  return (
    <Center
      flexDirection='column'
      minW='390px'
      w='100%'
      gap='25px'
      py='35px'
      color='gray.400'
    >
      <Divider w='80%' borderColor='gray.600' borderBottomWidth='1.5px' />
      <Flex gap='10px'>
        <Text {...footerTextStyles}>Studio Manager &#169; {year}. All rights reserved</Text>
        <Flex
          position='relative'
          gap='7px'
          _before={{
            content: '""',
            position: 'absolute',
            background: 'gray.400',
            width: '1px',
            top: '0',
            left: '0',
            height: '100%',
          }}
        >
          <Text {...footerTextStyles} ml='10px'>Powered by</Text>
          <Image src={SterlingLogoDark} w='20px' h='20px' />
        </Flex>
      </Flex>
      <Text fontSize={14} fontWeight='bold'>
        ver. {env.REACT_APP_VERSION} - {env.REACT_APP_SHA.substring(0, 7)}
      </Text>
      <Link
        as={RouterLink}
        target='_blank'
        to='https://www.studium33.com/privacy-policy'
        {...footerTextStyles}
      >
        Privacy Policy
      </Link>
    </Center>
  );
}
