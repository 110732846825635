import {
  TabPanel, TabPanels,
} from '@chakra-ui/react';

import InboxTable from './components/InboxTable';
import ProjectsTable from './components/ProjectsTable';
import SentTable from './components/SentTable';
import { NavigationTabs } from 'components/NavigationTabs';
import { TransferRequestList } from 'pages/transfers/projects-page/components/TransferRequestTable';

export function ProjectsPage({ tabName }: { tabName: string }): JSX.Element {
  return (
    <NavigationTabs tabName={tabName}>
      <TabPanels>
        <TabPanel p='0px'>
          <InboxTable />
        </TabPanel>
        <TabPanel p='0px'>
          <SentTable />
        </TabPanel>
        <TabPanel p='0px'>
          <TransferRequestList />
        </TabPanel>
        <TabPanel p='0px'>
          <ProjectsTable />
        </TabPanel>
      </TabPanels>
    </NavigationTabs>
  );
}
