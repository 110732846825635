import {
  Button,
  Flex, Image,
  useToast,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { Else, If, Then } from 'react-if';
import { Navigate, useLocation } from 'react-router-dom';

import { CredentialsForm, ILogin } from './login-page/CredentialsForm';
import { IMfa, MultiFactorForm } from './login-page/MultiFactorForm';
import {
  formatFireBaseError, isFireBaseError, logIn, verifyOtpa,
} from 'api/auth';
import SterlingSoundLogo from 'assets/sterling-sound-light-logo.svg';
import useUserContext from 'hooks/useUserContext';
import { logError } from 'utils/error';

export default function LoginPage(): JSX.Element {
  const { isLoggedIn, otpaRequired } = useUserContext();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const redirectTo = searchParams.get('redirectTo') || '/';
  searchParams.delete('redirectTo');
  const searchString = searchParams.toString();
  const redirectLocation = `${redirectTo}${searchString ? `?${searchString}` : ''}`;

  if (isLoggedIn) {
    console.log(`isLoggedIn. redirecting to ${redirectLocation}`);
    return <Navigate to={redirectLocation} />;
  }

  const onPasswordSubmit = async (data: ILogin, setError: UseFormSetError<ILogin>): Promise<void> => {
    const { email, password } = data;
    try {
      const otpaStatus = await logIn(email, password);

      if (!otpaStatus.needsOtpa) {
        window.location.href = redirectLocation;
      }
    } catch (e: any) {
      if (isFireBaseError(e)) {
        setError('password', { type: 'manual', message: formatFireBaseError(e) });
      }
      logError(e);
      throw e;
    }
  };

  interface VerifyOtpaResponse {
    message: string;
  }

  const onMfaSubmit = async (mfaCode: string, setError: UseFormSetError<IMfa>): Promise<void> => {
    try {
      await verifyOtpa(mfaCode);
      window.location.href = redirectLocation;
    } catch (e: any) {
      const { message } = (e as AxiosError<VerifyOtpaResponse>).response?.data || { message: 'An unknown error occurred.' };
      setError('pin1', { type: 'required', message });
    }
  };

  return (
    <Flex direction='column' align='center' w='auto' mx='auto'>
      <Image h='65px' mb='50px' src={SterlingSoundLogo} />
      <If condition={otpaRequired}>
        <Then>
          <MultiFactorForm submitCallback={onMfaSubmit} />
        </Then>
        <Else>
          <CredentialsForm submitCallback={onPasswordSubmit} redirectLocation={redirectLocation} />
        </Else>
      </If>
    </Flex>
  );
}
