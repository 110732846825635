import {
  Box, Flex, Image, Text,
} from '@chakra-ui/react';

import { PublicResource } from 'api/studioServerTypes';

export default function EngineerHeaderCard({
  OnlineName, OnlineTitle, SmallImageUrl,
}: PublicResource): JSX.Element | null {
  return (
    <Box
      backgroundColor='#0F1118'
      width='auto'
      minHeight='100px'
      borderRadius='15'
      position='static'
      overflow='visible'
      mt='35px'
    >
      <Flex
        flexDir='column'
        alignItems='center'
        p='7'
        gap='3'
        mt='-80px'
      >
        <Image
          border='solid 10px #171922'
          src={SmallImageUrl!}
          alt={OnlineName!}
          borderRadius='50%'
          width='90px'
        />
        <Text textAlign='center' fontSize='16px' mt={2}>{OnlineName!}</Text>
        <Text textAlign='center' color='gray.500' fontSize='14px'>{OnlineTitle!}</Text>
      </Flex>
    </Box>
  );
}
