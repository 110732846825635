import {
  Box, Container, Spinner, SpinnerProps,
} from '@chakra-ui/react';
import { HTMLAttributes } from 'react';

import ErrorPage from './error/ErrorPage';
import { logError } from 'utils/error';

const defaultSpinnerProps: SpinnerProps = {
  thickness: '4px',
  speed: '0.5s',
  color: 'gold.1000',
  m: 100,
  size: 'xl',
};

interface IAsyncWrapper extends HTMLAttributes<HTMLDivElement> {
  requests: { loading: boolean, error?: Error }[];
  keepChildrenMounted?: boolean;
  spinnerProps?: SpinnerProps;
  hideSpinner?: boolean;
  mountChildrenDiscrete?: boolean;
}

export function AsyncWrapper({
  requests,
  children,
  keepChildrenMounted = false,
  spinnerProps,
  hideSpinner = false,
  mountChildrenDiscrete = false,
}: IAsyncWrapper): JSX.Element {
  const error = requests.find((c) => c.error)?.error;
  if (error) {
    logError(error);
    return (
      <ErrorPage error={error} />
    );
  }

  const loading = requests.some((c) => c.loading);

  if (loading && !keepChildrenMounted) {
    return (
      <Container centerContent>
        <Spinner
          {...defaultSpinnerProps}
          {...spinnerProps}
          hidden={hideSpinner}
        />
      </Container>
    );
  }

  if (mountChildrenDiscrete) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {children}
      </>
    );
  }

  return (
    <Box display={loading ? 'none' : 'block'}>
      {children}
    </Box>
  );
}
