import { Route } from 'react-router';

import AdminResetPasswordPage from '../pages/admin/AdminResetPasswordPage';
import PrivateRoute from 'components/PrivateRoute';

export default function useAdminRoutes(): JSX.Element {
  return (
    <Route
      path='/admin/*'
      element={<PrivateRoute />}
    >
      <Route
        path='change-password'
        element={<AdminResetPasswordPage />}
      />
    </Route>
  );
}
