import {
  Button,
  Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, Text, useToast,
} from '@chakra-ui/react';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { firebaseAuth, formatFireBaseError, isFireBaseError } from 'api/auth';
import Warning from 'assets/icons/warning.png';
import AsyncButton from 'components/form/AsyncButton';
import { AuthError } from 'StudioError';

interface IPasswordReset {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function ChangePasswordPage(): JSX.Element {
  const formMethods = useForm<IPasswordReset>();
  const {
    register, handleSubmit, formState, setError, reset,
  } = formMethods;
  const { errors } = formState;
  const toast = useToast();
  const nav = useNavigate();

  function showSuccessToast(): void {
    toast({
      title: 'Success',
      description: 'Password updated',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  // wip, still will reset password so use at own risk
  // test
  async function onSubmit({ currentPassword, newPassword, confirmPassword }: IPasswordReset): Promise<void> {
    if (currentPassword.length === 0) {
      setError('confirmPassword', { type: 'required', message: 'Password cannot be empty' });
      return;
    }
    if (newPassword !== confirmPassword) {
      setError('confirmPassword', { type: 'manual', message: 'Passwords do not match' });
      return;
    }
    if (newPassword.length === 0) {
      setError('confirmPassword', { type: 'required', message: 'Password cannot be empty' });
      return;
    }

    const user = firebaseAuth.currentUser;
    if (!user || !user.email) {
      throw new AuthError('User not found');
    }

    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      await reauthenticateWithCredential(user, credential);
    } catch (e) {
      if (isFireBaseError(e)) {
        setError('confirmPassword', { type: 'manual', message: formatFireBaseError(e) });
      } else {
        throw e;
      }
      return;
    }

    try {
      await updatePassword(user, newPassword);
    } catch (e) {
      if (isFireBaseError(e)) {
        setError('confirmPassword', { type: 'manual', message: formatFireBaseError(e) });
      } else {
        throw e;
      }
      return;
    }
    showSuccessToast();
    reset();
  }

  return (
    <FormControl isInvalid={!!errors.confirmPassword?.message} maxW={[450]}>
      <FormProvider {...formMethods}>
        <form>
          <FormLabel>Current password:</FormLabel>
          <Input
            size='lg'
            autoFocus
            type='password'
            {...register('currentPassword')}
          />
          <FormLabel mt='25px'>New password:</FormLabel>
          <Input
            size='lg'
            type='password'
            {...register('newPassword')}
          />
          <FormLabel mt='25px'>Confirm password:</FormLabel>
          <Input
            size='lg'
            type='password'
            {...register('confirmPassword')}
          />
          <FormErrorMessage mt='10px' gap='10px'>
            <Image src={Warning} boxSize='15px' />
            <Text pt='5px'>{errors.confirmPassword?.message} </Text>
          </FormErrorMessage>
          <Flex gap='20px'>
            <AsyncButton
              formState={formState}
              spinAfterPromiseResolved={false}
              mt='50px'
              type='submit'
              h='54'
              onClick={handleSubmit(onSubmit)}
            >
              Save changes
            </AsyncButton>
            <Button
              mt='50px'
              variant='link'
              type='reset'
              onClick={() => nav(-1)}
            >
              Cancel
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </FormControl>
  );
}
