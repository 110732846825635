import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const solid = defineStyle((props) => ({
  background: '#D2A969',
  height: '54px',
  color: 'black',
  borderRadius: '8px',
  _hover: {
    background: '#AD8951',
  },
  fontSize: 'ß16px',
  fontWeight: '600',
}));

const back = defineStyle((props) => ({
  background: 'gray.600',
  height: '54px',
  color: 'white',
  borderRadius: '8px',
  _hover: {
    background: 'gray.700',
  },
  fontSize: '16px',
  fontWeight: '600',
}));

const solidDark = defineStyle((props) => ({
  background: 'gray.700',
  height: '54px',
  color: 'white',
  borderRadius: '8px',
  _hover: {
    background: 'gray.600',
  },
  fontSize: 'ß16px',
  fontWeight: '600',
}));

const outline = defineStyle({
  borderColor: 'gold.1000',
  color: 'white',
  borderRadius: '8px',
  borderWidth: '2px',
  px: '25px',
});

const login = defineStyle({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '7px',
  width: '100%',
  height: '55px',
  mt: '25px',
  borderRadius: '8px',
  bgColor: 'gold.100000',
  fontWeight: '600',
  fontSize: '16px',
  fontFamily: 'Poppins',
});

export const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: 'Poppins',
  },
  defaultProps: {
    variant: 'solid',
  },
  variants: {
    login,
    solid,
    outline,
    solidDark,
    back,
  },
});
