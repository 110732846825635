export function ActivityIcon(): JSX.Element {
  return (
    <svg width='26' height='15' viewBox='0 0 26 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.7729 0L20.6479 2.875L14.5229 8.9375L9.52295 3.9375L0.272949 13.3125L2.02295 15L9.52295 7.5L14.5229 12.5L22.3979 4.625L25.2729 7.5V0H17.7729Z'
        fill='currentColor'
      />
    </svg>
  );
}
