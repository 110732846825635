import {
  Flex,
  Input,
  Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, PinInput, PinInputField, Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useAsync } from 'react-use';

import { AsyncWrapper } from './AsyncWrapper';
import AsyncButton from './form/AsyncButton';
import { BasicModalHeader, ModalFrame } from './modal-frame/ModalFrame';
import ArchiveUploadIcon from 'assets/icons/upload-archive.svg';
import useAxios from 'hooks/useAxios';

export interface ISmsVerifyModal {
  isOpen: boolean;
  onVerify: (pin: string, phoneNumber: string) => Promise<void>;
  onClose: () => void;
  transferId: number;
}

export default function SmsVerifyModal({
  isOpen, onClose, onVerify, transferId,
}: ISmsVerifyModal): JSX.Element {
  const [pin, setPin] = useState<string>('');
  const [smsRequest, sendSmsRequest] = useAxios<{ phoneNumber: string }>({
    url: `/file-api/transfers/${transferId}/sms`,
    method: 'POST',
  }, true);

  useAsync(async () => {
    if (isOpen) await sendSmsRequest();
  }, [isOpen]);

  async function handleVerify(pinCode: string): Promise<void> {
    try {
      await onVerify(pinCode, smsRequest.data!.phoneNumber);
    } finally {
      setPin('');
    }
  }

  async function handleClose(): Promise<void> {
    setPin('');
    onClose();
  }

  return (
    <ModalFrame
      header={<BasicModalHeader title='SMS Verification' />}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Flex
        direction='column'
        gap='8px'
      >
        <AsyncWrapper requests={[smsRequest]}>
          <Text textAlign='center'>We&apos;ve sent a verification code to your number {smsRequest.data?.phoneNumber}</Text>
          <Flex mt='2' mb='3' justifyContent='center'>
            <Input textAlign='center' variant='outline' width='200px' value={pin} onChange={(event): void => setPin(event.target.value)} />
          </Flex>
          <AsyncButton
            onClick={() => handleVerify(pin)}
          >Verify
          </AsyncButton>
        </AsyncWrapper>
      </Flex>
    </ModalFrame>
  );
}
