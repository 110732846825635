import {
  Button, Flex, Heading, Text,
} from '@chakra-ui/react';
import axios from 'axios';

export default function SessionPayment(): JSX.Element {
  const onStripePortalClick = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await axios.post('/api/stripe/create-portal-session', {
      returnUrl: window.location.href,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    window.location.replace(data);
  };

  return (
    <Flex direction='column' gap='15px' mt='50px' ml='20px'>
      <Heading
        fontSize='18px'
      >Payment settings
      </Heading>
      <Text
        mb='15px'
        maxWidth={400}
      >To make an adjustment to your payment settings, please visit Stripe.
      </Text>
      <Button
        onClick={onStripePortalClick}
        width='200px'
      >
        Go to my Stripe
      </Button>
    </Flex>
  );
}
