import {
  ReactNode, createContext, useContext, useMemo, useState,
} from 'react';

import { studioServerApi } from 'api/studioServerApi';
import type {
  DisplayContactClient as Client, Contact, LineItem,
  PublicResource as Resource,
} from 'api/studioServerTypes';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';
import type { SessionDetailsFormValues as SessionDetails } from 'pages/booking/steps/SessionDetails';

type LineItemMetadata = { Description: string, Rate: number };

export interface BookingState {
  contact: Contact | undefined;
  client: Client | undefined;
  resource: Resource | undefined;
  uploadDate: string | undefined;
  lineItems: (LineItem & LineItemMetadata)[] | undefined;
  mastersByDate: string | undefined;
  sessionDetails: SessionDetails | undefined;
  timerStartTime: number;
}

interface BookingContextProps extends BookingState {
  setClient: (client: Client) => void;
  setResource: (resource: Resource) => void;
  setLineItems: (lineItems: (LineItem & LineItemMetadata)[]) => void;
  setMastersByDate: (mastersByDate: string | undefined) => void;
  setUploadDate: (uploadDate: string) => void;
  setSessionDetails: (sessionDetails: SessionDetails) => void;
}

const defaultBookingState: BookingState = {
  contact: undefined,
  client: undefined,
  resource: undefined,
  lineItems: undefined,
  uploadDate: undefined,
  mastersByDate: undefined,
  sessionDetails: undefined,
  timerStartTime: Date.now(),
};

const BookingContext = createContext<BookingContextProps | undefined>(undefined);

export const useBookingContext = (): BookingContextProps => {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error('useBookingContext must be used within a BookingProvider');
  }
  return context;
};

interface BookingProviderProps {
  children: ReactNode;
}

export function BookingProvider({ children }: BookingProviderProps): JSX.Element {
  const { user } = useUserContext();
  const [bookingState, setBookingState] = useState<BookingState>(defaultBookingState);

  const contactQueryKey = ['/api/contacts/{contactIdentifier}/display', user?.ContactIdentifier];
  const contactQueryFn = () => studioServerApi.GET('/api/contacts/{contactIdentifier}/display', {
    params: { path: { contactIdentifier: user!.ContactIdentifier } },
  });
  const contactQuery = useApiQuery({ queryKey: contactQueryKey, queryFn: contactQueryFn });

  const setClient = (client: Client) => {
    setBookingState((prevState) => ({ ...prevState, client }));
  };
  const setResource = (resource: Resource) => {
    setBookingState((prevState) => ({ ...prevState, resource }));
  };
  const setLineItems = (lineItems: (LineItem & LineItemMetadata)[]) => {
    setBookingState((prevState) => ({ ...prevState, lineItems }));
  };
  const setMastersByDate = (mastersByDate: string | undefined) => {
    setBookingState((prevState) => ({ ...prevState, mastersByDate, timerStartTime: Date.now() }));
  };
  const setUploadDate = (uploadDate: string) => {
    setBookingState((prevState) => ({ ...prevState, uploadDate }));
  };
  const setSessionDetails = (sessionDetails: SessionDetails) => {
    setBookingState((prevState) => ({ ...prevState, sessionDetails }));
  };

  const value = useMemo(
    () => {
      const contact = contactQuery.apiResult?.data;

      return {
        ...bookingState,
        contact,
        setClient,
        setResource,
        setLineItems,
        setMastersByDate,
        setSessionDetails,
        setUploadDate,
      };
    },
    [bookingState, contactQuery.apiResult?.data],
  );

  return (
    <BookingContext.Provider value={value}>
      {children}
    </BookingContext.Provider>
  );
}
