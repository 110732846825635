import {
  Flex, SimpleGrid, Stack, Text,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';

import { studioServerApi } from 'api/studioServerApi';
import { PublicResource } from 'api/studioServerTypes';
import QuerySuspense from 'components/QuerySuspense';
import { useBookingContext } from 'contexts/booking/BookingContext';
import { useApiQuery } from 'hooks/useApiQuery';
import EngineerCard from 'pages/booking/components/EngineerCard';

export default function SelectEngineer(): JSX.Element {
  const [searchParams] = useSearchParams();
  const engQuery = searchParams.get('eng');
  const { nextStep } = useWizard();
  const { setResource, resource } = useBookingContext();

  const [loading, setLoading] = useState(true);

  const resourcesQuery = useApiQuery({
    queryKey: ['/api/resources/public'],
    queryFn: () => studioServerApi.GET('/api/resources/public'),
  });
  const locations = useMemo(() => {
    if (!resourcesQuery.apiResult?.data) return [];
    return resourcesQuery.apiResult.data.reduce<Record<string, PublicResource[]>>((acc, engineer) => {
      const location = `${engineer.City}, ${engineer.StateProvince}`;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(engineer);
      return acc;
    }, {});
  }, [resourcesQuery.apiResult?.data]);

  const onSelect = async (engineer: PublicResource): Promise<void> => {
    setResource(engineer);
    await nextStep();
  };

  useEffect(() => {
    if (!engQuery || !resourcesQuery.apiResult?.data || resource) {
      setLoading(false);
      return;
    }

    const setEngineerDefault = async (): Promise<void> => {
      const engineer = resourcesQuery.apiResult?.data?.find((e) => e.OnlineSlug === engQuery);
      if (engineer) {
        setResource(engineer);
        await nextStep();
      }
      setLoading(false);
    };

    void setEngineerDefault();
  }, [engQuery, resourcesQuery.apiResult?.data, resource, nextStep, setResource]);

  if (loading) {
    return <></>;
  }

  return (
    <QuerySuspense queries={[resourcesQuery.queryResult]}>
      <Stack justifyContent='center'>
        <Text fontSize={['xl', '2xl']} fontWeight='bold' marginTop='0' textAlign='center'>Select an Engineer</Text>
        {Object.entries(locations!).map(([location, engineers]) => (
          <Flex key={location} justifyContent='center' alignItems='center' flexDir='column' marginBottom='50'>
            <Text textAlign='center' color='gray.300' fontSize='18' marginBottom='25'>{location}</Text>
            <SimpleGrid columns={2} spacing='20px'>
              {engineers?.map((engineer) => (
                <EngineerCard
                  key={engineer.ResourceIdentifier}
                  onClick={() => onSelect(engineer)}
                  {...engineer}
                />
              ))}
            </SimpleGrid>
          </Flex>
        ))}
      </Stack>
    </QuerySuspense>
  );
}
