import {
  Box,
  Flex,
  Progress,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepStatus,
  Stepper,
  Text,
} from '@chakra-ui/react';
import { When } from 'react-if';
import { useWizard } from 'react-use-wizard';

export type WizardStepperProps = {
  steps: {
    stepTitle: string;
    key: string;
    hidden?: boolean;
  }[];
};

export function WizardStepper({ steps }: WizardStepperProps): JSX.Element {
  const { activeStep } = useWizard();
  const displaySteps = steps.filter((step) => !step.hidden);

  // find the most recent non-hidden index
  let lastVisibleStepIndex = activeStep;
  while (lastVisibleStepIndex >= 0 && steps[lastVisibleStepIndex].hidden) {
    lastVisibleStepIndex -= 1;
  }

  const activeKey = lastVisibleStepIndex >= 0 ? steps[lastVisibleStepIndex].key : null; // the key of the prior non-hidden step, if exists
  const priorNonHiddenIndex = activeKey !== null ? displaySteps.findIndex((step) => step.key === activeKey) : 0;
  const effectiveActiveIndex = priorNonHiddenIndex !== -1 ? priorNonHiddenIndex : 0;

  const max = displaySteps.length - 1;
  const progressPercent = (effectiveActiveIndex / max) * 100;

  return (
    <When condition={displaySteps.length}>
      <Box position='relative' px='25px' mt='10px'>
        <Stepper size='sm' gap='0' index={effectiveActiveIndex}>
          {displaySteps.map((step, index) => (
            <Step key={`wizard-step-${step.key}`}>
              <Flex flexDir='column' alignItems='center' alignContent='center' width='34px'>
                <StepIndicator
                  bg='gray.900'
                  borderWidth='1px'
                  alignSelf={index === 0 ? 'baseline' : index === 5 ? 'end' : 'center'}
                  sx={{
                    'width': '14px',
                    'height': '14px',
                    '[data-status=complete] &': {
                      background: 'gold.1000',
                      borderColor: 'black',
                    },
                    '[data-status=active] &': {
                      borderColor: 'white',
                      borderWidth: '3px',
                    },
                    '[data-status=incomplete] &': {
                      borderColor: 'white',
                      borderWidth: '1px',
                    },
                  }}
                >
                  <StepStatus
                    complete={<StepIcon __css={{ fill: 'white' }} />}
                  />
                </StepIndicator>
                <StepDescription as={Text} textAlign='center' mt='14px'>
                  {step.stepTitle}
                </StepDescription>
              </Flex>
            </Step>
          ))}
        </Stepper>
        <Progress
          value={progressPercent}
          position='absolute'
          height='3px'
          width='calc(100% - 56px)'
          zIndex='-1'
          colorScheme='yellow'
          top='6px'
        />
      </Box>
    </When>
  );
}
