import {
  Button, Stack, Text,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { When } from 'react-if';
import { Wizard, useWizard } from 'react-use-wizard';

import { AddExistingContactStep } from './add-contact-wizard-steps/AddExistingContactStep';
import { AddNewContactStep } from './add-contact-wizard-steps/AddNewContactStep';
import { AddContactProvider } from './hooks/useAddContact';
import { WizardStep } from 'components/wizard/WizardWrapper';
import { MySterlingNavigationTabs } from 'pages/booking/components/MySterlingNavigationTabs';

export type PassThroughValues = {
  email: string;
  isUploader: boolean;
  isReceiver: boolean;
};

const steps: WizardStep[] = [
  {
    component: AddExistingContactStep,
    key: 'add-existing-contact-step',
    stepTitle: 'Add Contact',
    allowBack: false,
  },
  {
    component: AddNewContactStep,
    key: 'add-new-contact-step',
    stepTitle: 'Add New Contact',
    allowBack: true,
  },
];

function AddContactWrapper({ children }: PropsWithChildren): JSX.Element {
  const { activeStep, previousStep } = useWizard();
  const { stepTitle, allowBack } = steps[activeStep];

  return (
    <Stack gap='20px'>
      <MySterlingNavigationTabs tabName='sessions' />
      <When condition={allowBack}>
        <Button onClick={previousStep} backgroundColor='gray.600' width='65px' color='white'>Back</Button>
      </When>
      <Text fontSize='24px' fontWeight='600' alignSelf='center'>{stepTitle}</Text>
      {children}
    </Stack>
  );
}

export function AddContactWizard(): JSX.Element {
  return (
    <AddContactProvider>
      <Wizard wrapper={<AddContactWrapper />}>
        {steps.map((step) => (
          <step.component key={step.key} />
        ))}
      </Wizard>
    </AddContactProvider>
  );
}
