/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Box, Flex, Heading, Image, Link, Stack, Switch, Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import PhoneIcon from 'assets/icons/chat-icon-gray.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import useAxios from 'hooks/useAxios';
import { Contacts, SmsEnrollment } from 'types/api';

export default function SmsOptIn(): JSX.Element {
  const [hideSmsOptInAlert, setHideSmsOptInAlert] = useLocalStorage<boolean>('hide-sms-opt-in-alert');
  const [contact] = useAxios<Contacts>('/file-api/contacts');
  const phoneNumber = contact.data?.MobileTelephone || 'N/A'; // TODO: Remove N/A when invalid state is implemented
  const [smsEnrollment] = useAxios<SmsEnrollment>('/file-api/sms/enroll', !phoneNumber);
  const [isSwitchOn, setIsSwitchOn] = useState(!!smsEnrollment.data?.OptIn);

  // Ensure isSwitchOn is set correctly when the component mounts.
  // Without this, default on state will show up with chakra's
  // default light-blue.
  useEffect(() => {
    setIsSwitchOn(!!smsEnrollment.data?.OptIn);
  }, [smsEnrollment.data?.OptIn]);

  const onSmsEnabledChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { checked } = e.target;
    setIsSwitchOn(checked);
    const oldHideSmsOptInAlert = hideSmsOptInAlert;
    setHideSmsOptInAlert(true);
    await axios.post('/file-api/sms/enroll', { OptIn: checked });
    if (!oldHideSmsOptInAlert) window.location.reload();
  };

  return (
  // <If condition={!phoneNumber}>
  //   <Then>
  //     <SmsInvalidState />
  //   </Then>
  //   <Else>
    <AsyncWrapper requests={[smsEnrollment, contact]}>
      <Box marginLeft='100' border='gray.800'>
        <Stack marginBottom='50px' minW='1000px'>
          <Heading fontSize='4xl' as='b'>Subscriptions Settings</Heading>
          {/* <Text>To make changes to your number, please contact Sterling Sound.</Text> */}
        </Stack>
        <Box bg='gray.800' borderRadius='15' p='35px 45px' w='75%' minWidth='920px' marginBottom='70px'>
          <Flex direction='row' gap='4%'>
            <Flex dir='row' gap='5' alignItems='center'>
              <Flex direction='column'>
                <Flex gap='7px' alignItems='center'>
                  <Image src={PhoneIcon} h='23px' />
                  <Heading fontSize='22px' color='gray.400' marginBottom='7px'>SMS Notifications</Heading>
                </Flex>
                <Text marginBottom='10px'>You agree to receive automated text message updates regarding your mastering sessions with Sterling Sound at:</Text>
                <Flex gap='10px' alignItems='center'>
                  <div style={{ borderLeft: '3px solid #718096', height: '20px', borderRadius: '20%' }} />
                  <Text fontSize='22px'>{ phoneNumber }</Text>
                </Flex>
                <Text marginTop='15px' marginBottom='10px'>
                  If you require changes to your number, please contact Sterling Sound. Visit our
                  <Link color='gray.500'>Privacy Policy</Link>
                </Text>
              </Flex>
            </Flex>
            <Flex gap='7px' marginTop='7' minWidth='200px' direction='column'>
              <Text marginBottom='13px'>If you require changes to your number, please contact Sterling Sound.</Text>
              <Flex gap='8px' alignItems='center'>
                <Switch
                  size='lg'
                  defaultChecked={!!smsEnrollment.data?.OptIn}
                  onChange={onSmsEnabledChange}
                  sx={{
                    '& .chakra-switch__track': {
                      bg: isSwitchOn ? 'gold.1000' : '',
                    },
                  }}
                />
                <Text>SMS Transfer notifications</Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </AsyncWrapper>
  //   </Else>
  // </If>
  );
}
