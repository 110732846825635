import {
  TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';

import ChangePasswordPage from './ChangePasswordPage';
import { PasswordSettingsIcon } from './components/PasswordSettingsIcon';
import { TwoFactorIcon } from './components/TwoFactorIcon';
import TwoFactorPage from './TwoFactorPage';
import { StudiumTableTab } from 'components/StudiumTableTab';

export default function SecuritySettingsPage(): JSX.Element {
  return (
    <Tabs variant='unstyled' isLazy lazyBehavior='unmount'>
      <TabList mb='64px'>
        <StudiumTableTab icon={<PasswordSettingsIcon />}>Password Settings</StudiumTableTab>
        <StudiumTableTab icon={<TwoFactorIcon />}>2-Step Verification</StudiumTableTab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <ChangePasswordPage />
        </TabPanel>
        <TabPanel>
          <TwoFactorPage />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
