import SentIcon from 'assets/icons/sent.svg';
import TransfersTable from 'pages/transfers/project-overview-page/components/TransfersTable';

const emptyTableState = {
  message: 'You have no transfers in your outbox',
  icon: SentIcon,
};

export default function SentTable(): JSX.Element {
  return (
    <TransfersTable
      path='/file-api/transfers/sent'
      emptyTableState={emptyTableState}
    />
  );
}
