import { useLocation } from 'react-router-dom';

function useIsSpecificRoute() {
  const { pathname } = useLocation();

  const isBookingRoute = pathname.startsWith('/booking');
  const isArchivesRoute = pathname.startsWith('/archives');
  const isTransfersRoute = pathname.startsWith('/transfers');

  return {
    isBookingRoute,
    isArchivesRoute,
    isTransfersRoute,
  };
}

export default useIsSpecificRoute;
