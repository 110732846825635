import {
  Button, ButtonOptions, ThemingProps, chakra,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import {
  ButtonHTMLAttributes, ReactNode,
} from 'react';
import { useFormState } from 'react-hook-form';

interface IAsyncButton extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonOptions, ThemingProps<'Button'> {
  onClick(e?: React.MouseEvent<HTMLButtonElement>): Promise<void>;
  children: ReactNode;
  spinAfterPromiseResolved?: boolean;

  formState?: ReturnType<typeof useFormState>;
}

function AsyncButton({
  children,
  spinAfterPromiseResolved = true,
  formState,
  onClick,
  ...props
}: IAsyncButton): JSX.Element {
  const mutation = useMutation({
    mutationFn: onClick,
    onSettled: () => {
      if (formState && !formState.isValid) {
        mutation.reset();
      }
      if (!spinAfterPromiseResolved) {
        mutation.reset();
      }
    },
    onError: () => {
      mutation.reset();
    },
  });

  const handleAsyncClick = async (event?: React.MouseEvent<HTMLButtonElement>) => {
    await mutation.mutateAsync(event);
  };

  return (
    <Button
      {...props}
      onClick={handleAsyncClick}
      isLoading={mutation.isPending || (mutation.isSuccess && spinAfterPromiseResolved)}
    >
      {children}
    </Button>
  );
}

export default chakra(AsyncButton);
