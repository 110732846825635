import { Flex, TabList, Tabs } from '@chakra-ui/react';
import { useMemo } from 'react';
import { When } from 'react-if';
import { useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { StudiumTableTab } from './StudiumTableTab';
import { SearchBar } from './table/SearchBar';
import { InboxIcon } from 'pages/transfers/projects-page/components/icons/InboxIcon';
import { ProjectsIcon } from 'pages/transfers/projects-page/components/icons/ProjectsIcon';
import { RequestsIcon } from 'pages/transfers/projects-page/components/icons/RequestsIcon';
import { SentIcon } from 'pages/transfers/projects-page/components/icons/SentIcon';

export const tabMap: Record<string, number> = {
  inbox: 0,
  sent: 1,
  requests: 2,
  projects: 3,
};

export type NavigationTabsProps = {
  tabName: string,
  hideSearchBar?: boolean,
  disableSelectedStyling?: boolean,
  children?: JSX.Element
};

export function NavigationTabs({
  tabName, hideSearchBar = false, disableSelectedStyling = false, children,
}: NavigationTabsProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  function onEnter(searchTerm: string): void {
    if (!searchTerm) params.delete('search');
    else params.set('search', searchTerm);
    navigate(`${location.pathname}?${params.toString()}`);
  }

  return (
    <Tabs
      index={tabMap[tabName || 'projects']}
      isLazy
      lazyBehavior='unmount'
      variant='unstyled'
    >
      <Flex
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
        gap='4'
        justifyContent='space-between'
      >
        <TabList
          as={Flex}
          gap='2'
        >
          <RouterLink to='/transfers/inbox'>
            <StudiumTableTab icon={InboxIcon()} disableSelectedStyling={disableSelectedStyling}>Inbox</StudiumTableTab>
          </RouterLink>
          <RouterLink to='/transfers/sent'>
            <StudiumTableTab icon={SentIcon()} disableSelectedStyling={disableSelectedStyling}>Sent</StudiumTableTab>
          </RouterLink>
          <RouterLink to='/transfers/requests'>
            <StudiumTableTab icon={RequestsIcon()} disableSelectedStyling={disableSelectedStyling}>Requests</StudiumTableTab>
          </RouterLink>
          <RouterLink to='/transfers/projects'>
            <StudiumTableTab icon={ProjectsIcon()} disableSelectedStyling={disableSelectedStyling}>Projects</StudiumTableTab>
          </RouterLink>
        </TabList>
        <Flex
          flexDirection='row'
          w='538px'
        >
          <When condition={!hideSearchBar}>
            <SearchBar onEnter={onEnter} />
          </When>
        </Flex>
      </Flex>
      {children}
    </Tabs>
  );
}
