import {
  Button, ButtonGroup, Flex, FormLabel, Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Select,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { versionOptions } from './helpers/version';
import { studioServerApi } from 'api/studioServerApi';
import AsyncButton from 'components/form/AsyncButton';
import { useApiQuery } from 'hooks/useApiQuery';

interface IEditFileMetadataModal {
  isOpen: boolean;
  onClose: () => void;
  files: { FileId: number, FileName: string }[];
  projectIdentifier: string;
}

interface IForm {
  ProjectSongIdentifier?: string
  Version?: string
}

const selectProps = {
  size: 'lg',
  fontSize: '14px',
  _focusVisible: {
    borderColor: 'gray.400',
  },
};

export default function EditFileMetadataModal({
  isOpen, onClose, files, projectIdentifier,
}: IEditFileMetadataModal): JSX.Element {
  const { register, handleSubmit, formState } = useForm<IForm>({});
  const queryClient = useQueryClient();
  const songQuery = useApiQuery({
    queryKey: [`/api/projects/${projectIdentifier}/project-songs/display`],
    queryFn: () => studioServerApi.GET('/api/projects/{projectIdentifier}/project-songs/display', {
      params: {
        path: {
          projectIdentifier,
        },
      },
    }),
  });

  const songOptions = useMemo(() => {
    if (songQuery.apiResult?.data) {
      return songQuery.apiResult?.data.map((song) => (
        <option key={song.ProjectSongIdentifier} value={song.ProjectSongIdentifier}>{song.SongName}</option>
      ));
    }

    return [];
  }, [songQuery.apiResult?.data]);

  const onSubmit = handleSubmit(async (data) => {
    if (data.ProjectSongIdentifier === '') {
      delete data.ProjectSongIdentifier;
    }

    if (data.Version === '') {
      delete data.Version;
    }

    const dataToPost = files.map((f) => ({
      FileId: f.FileId,
      ...data,
    }));

    await axios.put('/file-api/files/metadata', dataToPost);
    await queryClient.invalidateQueries({ queryKey: ['file-table'] });
    onClose();
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent py='35px' px='40px' borderRadius='15px' gap='25px'>
        <ModalHeader p='0'>
          <Heading fontSize='22px' fontWeight='700px'>{`Edit metadata for ${files.length} ${pluralize('file', files.length)}`}</Heading>
        </ModalHeader>
        <ModalBody p='0'>
          <form>
            <Flex direction='column' gap='25px'>
              <Flex direction='column'>
                <FormLabel>Version</FormLabel>
                <Select
                  {...register('Version')}
                  {...selectProps}
                  defaultValue=''
                >
                  <option value='' disabled>Select version</option>
                  {versionOptions.map((versionOption) => (
                    <option key={versionOption.value} value={versionOption.value}>{versionOption.label}</option>
                  ))}
                </Select>
              </Flex>
              <Flex direction='column'>
                {/* // Use the New IconLabel/FormLabel components here */}
                <FormLabel>Song</FormLabel>
                <Select
                  {...register('ProjectSongIdentifier')}
                  {...selectProps}
                  defaultValue=''
                >
                  <option value='' disabled>Select Song</option>
                  {songOptions}
                </Select>
              </Flex>
              <ButtonGroup size='100%' spacing='15px'>
                <AsyncButton
                  formState={formState}
                  type='submit'
                  onClick={onSubmit}
                  w='100%'
                  variant='solid'
                >
                  Update
                </AsyncButton>
                <Button
                  w='100%'
                  onClick={onClose}
                  variant='outline'
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
