import { Box, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffectOnce, useInterval } from 'react-use';
import { Wizard, useWizard } from 'react-use-wizard';

import RegisterStep from './steps/RegisterStep';
import SuccessStep from './steps/SuccessStep';
import { VerifyStep } from './steps/VerifyStep';
import { firebaseAuth } from 'api/auth';
import useUserContext from 'hooks/useUserContext';

const REFRESH_SECONDS = 3000;

const stepIndexMap = {
  register: 0,
  verify: 1,
  success: 2,
};

const signUpSteps = [
  {
    component: RegisterStep,
    key: 'register-step',
  },
  {
    component: VerifyStep,
    key: 'verify-step',
  },
  {
    component: SuccessStep,
    key: 'success-step',
  },
];

function RefreshWrapper({ children }: React.PropsWithChildren): React.ReactNode {
  const { goToStep } = useWizard();
  const { refreshFirebase } = useUserContext();
  const toast = useToast();

  // prevents register step flickering on initial load.
  // only set to true once first refresh has started.
  const [shouldRender, setShouldRender] = useState(false);

  const handleFirebaseCheck = async (): Promise<void> => {
    setShouldRender(true);
    const { currentUser } = firebaseAuth;
    if (!currentUser) {
      goToStep(stepIndexMap.register);
      return;
    }
    if (currentUser?.emailVerified) {
      goToStep(stepIndexMap.success);
      return;
    }
    if (currentUser && !currentUser.emailVerified) {
      goToStep(stepIndexMap.verify);
    }
    const refreshedUser = await refreshFirebase();
    if (refreshedUser?.emailVerified) {
      toast({
        title: 'Success',
        description: 'Your email address has successfully been verified!',
        status: 'success',
        duration: 4500,
        isClosable: true,
      });
      goToStep(stepIndexMap.success);
    }
  };
  useEffectOnce(() => {
    void handleFirebaseCheck();
  });
  useInterval(handleFirebaseCheck, REFRESH_SECONDS);
  return shouldRender ? children : null;
}

export default function SignUpWizardPage(): JSX.Element {
  return (
    <Wizard wrapper={<RefreshWrapper />}>
      {signUpSteps.map((step) => (
        <Box paddingX='40px' paddingY='100px'>
          <step.component key={step.key} />
        </Box>
      ))}
    </Wizard>
  );
}
