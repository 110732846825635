import { BlockBlobTier } from '@azure/storage-blob';
import axios from 'axios';
import {
  orderBy,
} from 'lodash';
import PQueue from 'p-queue';
import { FailedAttemptError } from 'p-retry';

import { StallDetector } from './StallDetector';
import { TransferProgress } from './TransferProgress';
import { UploadError } from '../../StudioError';
import pRetry from 'external/pRetry';
import { BytesLoadedCallback, downloadBlob, uploadBlob } from 'hooks/transfer/blobClient';
import { Files, TransferAuthentications } from 'types/api';
import { logError } from 'utils/error';

interface ITransferParams {
  blobs: Files[],
  onProgress: (progress: TransferProgress) => void,
  auth: TransferAuthentications,
  concurrency: number
  abortController: AbortController;
  operation: 'download' | 'upload';
}

interface IDownloadTransferParams extends ITransferParams {
  operation: 'download';

  dirHandle: FileSystemDirectoryHandle,

}

interface IUploadTransferParams extends ITransferParams {
  operation: 'upload';
  tier: BlockBlobTier;
  files: File[];
  reuseBlobs: boolean;
}

export async function authenticateDownload(transferId: number, verificationCode?: string, phoneNumber?: string): Promise<TransferAuthentications> {
  const sasTokenRes = await axios.post<TransferAuthentications>(`/file-api/transfers/${transferId}/auth`, {
    verificationCode,
    phoneNumber,
  });
  return sasTokenRes.data;
}

export async function authenticateUpload(transferId: number): Promise<TransferAuthentications> {
  const authRes = await axios.post<TransferAuthentications>(`/file-api/transfers/${transferId}/auth?writeable=true`);
  return authRes.data;
}

export async function startDownload(params: IDownloadTransferParams): Promise<void> {
  async function download(file: Files, abortSignal: AbortSignal, onProgress: BytesLoadedCallback): Promise<void> {
    return await downloadBlob({
      auth: params.auth,
      transferFile: file,
      rootDirHandle: params.dirHandle,
      abortSignal,
      onProgress,
    });
  }

  await startTransfer(params, download);
}

export async function startUpload(params: IUploadTransferParams): Promise<void> {
  async function upload(file: Files, abortSignal: AbortSignal, onProgress: BytesLoadedCallback): Promise<void> {
    const fileHandle = params.files.find((f) => f.path === file.FilePath);

    if (!fileHandle) throw new UploadError('File not found in files array', file.FilePath);

    const metadata = await uploadBlob({
      auth: params.auth,
      tier: params.tier,
      transferFile: file,
      file: fileHandle,
      reuseBlobs: params.reuseBlobs,
      abortSignal,
      onProgress,
    });

    await axios.put(`/file-api/files/${file.FileId}/certify`, {
      metadata,
    });
  }

  await startTransfer(params, upload);
}

async function startTransfer(params: ITransferParams, workItem: (file: Files, abortSignal: AbortSignal, onProgress: BytesLoadedCallback)=>Promise<void>): Promise<void> {
  const { blobs } = params;

  const queue = new PQueue({
    concurrency: params.concurrency,
  });

  const transferProgress = new TransferProgress(blobs, params.onProgress);

  queue.on('add', (a) => {
    console.log(`Task added:  waiting: ${queue.size}  running: ${queue.pending}`);
  });

  queue.on('completed', () => {
    console.log(`Item completed:  waiting: ${queue.size}  running: ${queue.pending}`);
  });

  queue.on('error', (error) => {
    console.log(`Queue error.  waiting: ${queue.size}  running: ${queue.pending} error: ${error}`);
  });

  queue.on('idle', () => {
    console.log('Queue idle');
  });

  async function startUnit(file: Files): Promise<void> {
    await queue.add(async () => {
      await pRetry(async () => {
        transferProgress.startFile(file);

        const stallDetector = new StallDetector(params.abortController.signal);
        try {
          await workItem(file, stallDetector.signal, (loadedBytes: number) => {
            stallDetector.ping(loadedBytes);
            transferProgress.addBytes(file, loadedBytes);
          });

          transferProgress.completeFile(file);
        } finally {
          stallDetector.stop();
        }
      }, {
        maxTimeout: 2000,
        forever: true,
        signal: params.abortController.signal,
        shouldRetry: (err: FailedAttemptError) => !params.abortController.signal.aborted,
        onFailedAttempt: (err: FailedAttemptError) => {
          const errorState = `msg: [${err.message}] code: [${err.code}] name: [${err.name}] cause: [${err.cause}] file: [${file.RelativePath}/${file.FileName}]`;

          if (params.abortController.signal.aborted) {
            console.warn('Transfer already aborted.');
          } else if (
            err.message === 'Failed to fetch'
            || err.message === 'network error'
            || err.name === 'AbortError'
            || err.code === 'REQUEST_SEND_ERROR'
          ) {
            console.warn(`${params.operation} failed, retrying -- ${errorState}`);
            transferProgress.retryFile(file);
          } else {
            console.warn(`${params.operation} failed, terminating -- ${errorState}`);
            params.abortController.abort(errorState || 'Unknown Transfer error');
            transferProgress.failFile(file);
            transferProgress.failTransfer(errorState || 'Unknown Transfer error');
            throw err;
          }
        },
      });
    });
  }

  const tasks = orderBy(blobs, (c) => Number(c.FileSize), 'desc')
    .map(startUnit);

  try {
    await Promise.all(tasks);
    transferProgress.completeTransfer();
  } catch (err:any) {
    console.warn('Transfer queue failed', err);
    transferProgress.failTransfer(err.message || 'Unknown Transfer error');
    throw err;
  }
}
