import {
  Button, Flex, Image, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import Logo from 'assets/sterling-sound-light-logo.svg';

export default function SuccessStep(): JSX.Element {
  const navigate = useNavigate();
  return (
    <Flex alignItems='center' flexDirection='column'>
      <Image src={Logo} width='300px' />
      <Flex direction='column' maxWidth='372px' gap='25px'>
        <Text textAlign='center' fontSize='20px' mt='100px'>
          Your account has successfully been registered!
        </Text>
        <Button width='100%' onClick={() => navigate('/booking/new')}>Next</Button>
      </Flex>
    </Flex>
  );
}
