import {
  Box, Flex, Image, Text,
} from '@chakra-ui/react';
import { When } from 'react-if';

import { PublicResource } from 'api/studioServerTypes';
import AsyncButton from 'components/form/AsyncButton';

interface EngineerCardProps extends PublicResource {
  onClick?: () => Promise<void>;
}

export default function EngineerCard({
  OnlineName, OnlineTitle, SmallImageUrl, onClick,
}: EngineerCardProps): JSX.Element | null {
  return (
    <Box
      backgroundColor='#0F1118'
      maxWidth='180px'
      height='250px'
      borderRadius='15'
      position='relative'
      overflow='visible'
    >
      <Flex
        flexDir='column'
        alignItems='center'
        p='4'
        justifyContent='space-between'
        mt='0'
        height='100%'
      >
        <Flex
          flexDir='column'
          alignItems='center'
          justifyContent='start'
          flex='1'
        >
          <Image
            src={SmallImageUrl!}
            alt={OnlineName!}
            borderRadius='50%'
            width='60%'
            height='auto'
          />
          <Box mb='15px'>
            <Text textAlign='center' fontSize='14px' mt={4}>{OnlineName!}</Text>
            <Text textAlign='center' color='gray.500' fontSize='12px'>{OnlineTitle!}</Text>
          </Box>
        </Flex>
        <When condition={!!onClick}>
          <AsyncButton
            color='white'
            backgroundColor='gray.600'
            width='100%'
            height='35px'
            _hover={{ backgroundColor: 'gray.700' }}
            onClick={onClick!}
          >
            Book
          </AsyncButton>
        </When>
      </Flex>
    </Box>
  );
}
