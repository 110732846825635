import { useMemo } from 'react';
import { Else, If, Then } from 'react-if';

import TwoFactorVerification from './components/TwoFactorVerification';
import TwoFactorVerified from './components/TwoFactorVerified';
import useUserContext from 'hooks/useUserContext';

// we consider any enrollment after 25th feb 2024 as valid.
const validEnrollmentDate = new Date('2024-02-25');

export default function TwoFactorPage(): JSX.Element {
  const { otpaEnrollmentDate } = useUserContext();

  const isValidEnrollment = useMemo(() => otpaEnrollmentDate && otpaEnrollmentDate > validEnrollmentDate, [otpaEnrollmentDate]);

  return (
    <If condition={!!isValidEnrollment}>
      <Then>
        <TwoFactorVerified />
      </Then>
      <Else>
        <TwoFactorVerification />
      </Else>
    </If>
  );
}
