import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

import AsyncButton from 'components/form/AsyncButton';

export function TransferDeleteModal({
  transferId, transferName, isOpen, onClose,
}: { transferId: string,
  transferName?: string,
  isOpen: boolean,
  onClose: () => void }): JSX.Element {
  const cancelRef = useRef<any | undefined>();

  const toast = useToast();
  const navigate = useNavigate();

  async function handleDelete(): Promise<void> {
    await axios.delete(`/file-api/transfers/${transferId}`);
    toast({
      title: 'Transfer deleted',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
    navigate(-1);
  }

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='xl' fontWeight='bold'>
            Delete Transfer
          </AlertDialogHeader>
          <AlertDialogBody py='0'>
            Are you sure you want to delete {transferName || `transfer #${transferId}`}?
          </AlertDialogBody>
          <AlertDialogFooter gap='3'>
            <AsyncButton
              onClick={() => handleDelete()}
            >
              Delete
            </AsyncButton>
            <Button ref={cancelRef} onClick={onClose} variant='outline'>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
