import {
  Button, Flex, Image, Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { studioServerApi } from 'api/studioServerApi';
import OrderCompleteCheck from 'assets/icons/thank-you-order-check.svg';
import StudiumSpinner from 'components/StudiumSpinner';

async function processPaidInvoice(): Promise<{
  MastersByDate: string;
  ResourceName: string;
}> {
  const url = window.location.pathname;
  const segments = url.split('/');

  const contactIdentifier = segments[3];
  const projectIdentifier = segments[4];
  const projectInvoiceIdentifier = segments[5];
  const checkoutSessionId = segments[6];

  if (!contactIdentifier || !projectIdentifier || !projectInvoiceIdentifier || !checkoutSessionId) {
    throw new Error('Please ensure the URL format is correct.');
  }

  const response = await studioServerApi.POST('/api/project-invoices/process-paid-invoice', {
    body: {
      BaseUrl: window.location.origin,
      CheckoutSessionId: checkoutSessionId,
      ContactIdentifier: contactIdentifier,
      ProjectIdentifier: projectIdentifier,
      ProjectInvoiceIdentifier: projectInvoiceIdentifier,
    },
  });

  return { MastersByDate: response.data?.MastersByDate!, ResourceName: response.data?.ResourceName! };
}

export default function InvoicePaid(): JSX.Element {
  const navigate = useNavigate();
  const [mastersByDate, setMastersByDate] = useState<Date | undefined>(undefined);
  const [resourceName, setResourceName] = useState<string | undefined>(undefined);
  const hasProcessedInvoice = useRef(false);

  const { mutate } = useMutation({
    mutationFn: processPaidInvoice,
    onSuccess: (data) => {
      setMastersByDate(new Date(data.MastersByDate));
      setResourceName(data.ResourceName);
    },
  });

  useEffect(() => {
    if (!hasProcessedInvoice.current) {
      hasProcessedInvoice.current = true;

      mutate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    mastersByDate && resourceName ? (
      <Flex alignItems='center' flexDirection='column'>
        <Flex alignItems='center' direction='column' maxWidth='372px' width='350px' mt='100px'>
          <Image src={OrderCompleteCheck} width='62px' height='62px' mb='35px' />
          <Text textAlign='center' fontSize='20px' mb='10px'>
            Thanks for your booking!
          </Text>
          <Text textAlign='center' mb='15px'>
            Your session with
            <b> {resourceName}</b>
            {' '}
            is confirmed for
            <b> {format(mastersByDate, 'MMM dd, yyyy')}</b>
            .
          </Text>
          <Button
            width='100%'
            onClick={() => navigate('/booking/sessions/current')}
          >Go to my sessions
          </Button>
        </Flex>
      </Flex>
    ) : (
      <StudiumSpinner />
    )
  );
}
