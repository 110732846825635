import { components, paths } from 'studio-api-clients/studio-server';

export type Contact = components['schemas']['Contact'];
export type Client = components['schemas']['Client'];
export type PublicResource = paths['/api/resources/public']['get']['responses']['200']['content']['application/json'][number];
export type ContactClientDescriptors = components['schemas']['ContactDescriptor']['ContactClientDescriptors'];
export type ContactClientDescriptor = NonNullable<ContactClientDescriptors>[number];
export type DisplayContactClient = NonNullable<Pick<NonNullable<ContactClientDescriptors>[number], 'DisplayContactClient'>>['DisplayContactClient'];
export type DisplayContact = components['schemas']['DisplayContact'];
export type ProjectContact = components['schemas']['ProjectContact'];
export type ContactDescriptor = components['schemas']['ContactDescriptor'];
export type MySterlingProject = components['schemas']['MySterlingProject'];
export type DisplayRateGroupItem = components['schemas']['DisplayRateGroupItem'];
export type LineItem = components['schemas']['LineItem'];
export type DisplaySessionResources = components['schemas']['DisplaySessionResource'][];
export type Project = paths['/api/projects']['post']['responses']['200']['content']['application/json'];

export enum ProjectContactRoles {
  ProjectAdmin = 'ProjectAdmin',
  ProjectMember = 'ProjectMember',
  Uploader = 'Uploader',
  Receiver = 'Receiver',
}
