import {
  Box, Tab, Text,
} from '@chakra-ui/react';

export function TabWithIcon({ icon, label }: { icon: JSX.Element, label: string }): JSX.Element {
  return (
    <Tab gap='5px'>
      <Box height='15px' width='auto'>
        {icon}
      </Box>
      <Text fontSize='14px' fontWeight='700' fontFamily='Poppins'>
        {label}
      </Text>
    </Tab>
  );
}
