export function TwoFactorIcon(): JSX.Element {
  return (
    <svg width='20' height='24' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M9.96067 0.5L19.9213 2.71349V12.4771C19.9213 16.3114 17.3208 20.774 10.3066 23.3718L9.96067 23.5L9.6147 23.3718C2.60054 20.774 0 16.3114 0 12.4771V2.71349L9.96067 0.5ZM1.99213 4.31151V12.4771C1.99213 15.2322 3.80617 18.9822 9.96067 21.3725C16.1152 18.9822 17.9292 15.2322 17.9292 12.4771V4.31151L9.96067 2.54073L1.99213 4.31151Z'
        fill='currentColor'
      />
    </svg>
  );
}
