/* eslint-disable max-len */
export function UploadManagerIcon(): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='19' height='17' viewBox='0 0 19 17' fill='none'>
      <path
        d='M0.875 3.34376C0.875 2.90814 0.875 2.69034 0.911028 2.50922C1.05898 1.76542 1.64042 1.18398 2.38422 1.03603C2.56534 1 2.78314 1 3.21876 1H9.31255H15.4063C15.842 1 16.0598 1 16.2409 1.03603C16.9847 1.18398 17.5662 1.76542 17.7141 2.50922C17.7501 2.69034 17.7501 2.90814 17.7501 3.34376C17.7501 3.77939 17.7501 3.99719 17.7141 4.17831C17.5662 4.92211 16.9847 5.50355 16.2409 5.6515C16.0598 5.68753 15.842 5.68753 15.4063 5.68753H9.31255H3.21876C2.78314 5.68753 2.56534 5.68753 2.38422 5.6515C1.64042 5.50355 1.05898 4.92211 0.911028 4.17831C0.875 3.99719 0.875 3.77939 0.875 3.34376Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinejoin='round'
      />
      <path
        d='M1.8125 5.6875V12.2499C1.8125 14.0178 1.8125 14.9017 2.36168 15.4509C2.91085 16 3.79474 16 5.56252 16H6.50003H12.1251H13.0626C14.8303 16 15.7142 16 16.2634 15.4509C16.8126 14.9017 16.8126 14.0178 16.8126 12.2499V5.6875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.375 13L9.375 9M9.375 9L7.875 10.6667M9.375 9L10.875 10.6667'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
