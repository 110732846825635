/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  Flex, FormControl, FormErrorMessage, FormLabel, Image, Img, Input, Text, Textarea, useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import {
  AsyncSelect, OptionBase, Props, Select,
} from 'chakra-react-select';
import { useState } from 'react';
import {
  FormProvider, UseControllerProps, useController, useForm,
} from 'react-hook-form';
import { When } from 'react-if';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { CreateProjectModalHeader } from './CreateProjectModalHeader';
import { studioServerApi } from 'api/studioServerApi';
import { Project } from 'api/studioServerTypes';
import CreateProjectIcon from 'assets/icons/createproject.svg';
import WarningIcon from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';
import { ModalFrame } from 'components/modal-frame/ModalFrame';
import QuerySuspense from 'components/QuerySuspense';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';

export interface ICreateProjectModal {
  isOpen: boolean;
  onClose: () => void;
}

interface ResourceIdentifier extends OptionBase {
  label: string;
  value: string;
}

interface FormValues {
  ProjectName: string;
  ResourceIdentifier: ResourceIdentifier;
  ArtistIdentifier: string;
  Notes: string;
}

type ControlledSelectProps = UseControllerProps<FormValues> &
Props & {
  label: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function ControlledSelect({
  control,
  name,
  id,
  label,
  rules,
  ...props
}: ControlledSelectProps) {
  const {
    field: {
      onChange, onBlur, value, ref,
    },
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
  });

  return (
    <FormControl py={4} isInvalid={!!error} id={id}>
      <FormLabel>{label}</FormLabel>

      <Select
        isMulti
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}

const defaultValues: FormValues = {
  ProjectName: '',
  ResourceIdentifier: { label: '', value: '' },
  ArtistIdentifier: '',
  Notes: '',
};

async function promiseOptions(inputValue: string): Promise<{ value: string; label: string; }[]> {
  const artists = await studioServerApi.GET('/api/artists', {
    params: {
      query: {
        searchText: inputValue,
        skip: 0,
        take: 20,
      },
    },
  });

  const formattedArtists = artists.data!.map((a) => ({
    value: a.ArtistIdentifier!,
    label: a.Name!,
  }));
  return formattedArtists;
}

async function createProject(
  ProjectName: string,
  ResourceIdentifier: ResourceIdentifier,
  Notes: string,
  AccountNumber: number,
  selectedArtist: { value: string, label: string } | null,
): Promise<Project> {
  const projectResponse = await studioServerApi.POST('/api/projects', {
    body: {
      AccountNumber,
      ProjectName,
      // MixEngineerContactIdentifier,
      ResourceIdentifier: ResourceIdentifier.value,
      ArtistIdentifier: selectedArtist?.value ?? undefined,
      Notes: Notes.trim(),
    },
  });

  await studioServerApi.POST('/api/projects/{projectIdentifier}/sessions', {
    params: { path: { projectIdentifier: projectResponse.data!.ProjectIdentifier! } },
    // @ts-expect-error
    body: {
      Session: {
        AccountNumber,
        SessionIdentifier: uuidv4(),
        ProjectIdentifier: projectResponse.data!.ProjectIdentifier,
        SessionName: 'Default Session',
        ScheduledDate: new Date(1753, 0, 1, 0, 0, 0, 0).toISOString(),
        ScheduledDuration: 0,
        Canceled: false,
        Completed: false,
      },
      SessionEquipment: [],
      SessionResources: [{
        SessionResourceIdentifier: uuidv4(),
        ResourceIdentifier: ResourceIdentifier.value,
        Confirmed: false,
      }],
      WorkOrders: [],
    },
  });
  return projectResponse.data!;
}

export default function CreateProjectModal({ onClose }: ICreateProjectModal): JSX.Element {
  const { user } = useUserContext();
  const toast = useToast();
  const navigate = useNavigate();
  const formMethods = useForm<FormValues>({
    defaultValues,
  });
  const {
    formState, handleSubmit, register,
  } = formMethods;

  const { errors } = formState;
  const [selectedArtist, setSelectedArtist] = useState<{ value: string, label: string } | null>(null);
  const resourcesQuery = useApiQuery({
    queryKey: ['resources', 'bookable'],
    queryFn: () => studioServerApi.GET('/api/resources/bookable'),
  });

  function handleTeardown(): void {
    formMethods.reset();
    setSelectedArtist(null);
    onClose();
  }

  const { mutateAsync } = useMutation({
    mutationFn: async (formData: FormValues) => {
      if (!user?.AccountNumber) {
        throw new Error('User Account Number is missing');
      }
      const { ProjectName, Notes, ResourceIdentifier } = formData;
      const project = await createProject(ProjectName, ResourceIdentifier, Notes, user.AccountNumber, selectedArtist);
      return project;
    },
    onSuccess: (project) => {
      toast({
        title: 'Project created',
        status: 'success',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      navigate(`/transfers/projects/${project.ProjectNumber}/transfers`);
      onClose();
    },
    onError: () => {
      toast({
        title: 'Failed to create project',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    },
  });

  const onSubmit = async (formData: FormValues): Promise<void> => {
    await mutateAsync(formData);
  };

  return (
    <ModalFrame
      size='xl'
      header={<CreateProjectModalHeader />}
      isOpen
      onClose={() => handleTeardown()}
    >
      <QuerySuspense queries={[resourcesQuery.queryResult]}>
        <Flex direction='column'>
          <FormControl isInvalid={!!errors.ProjectName?.message || !!errors.ResourceIdentifier?.message}>
            <FormProvider {...formMethods}>
              <form>
                <Text marginBottom='10px'>Project Name</Text>
                <Input
                  {...register('ProjectName', { required: 'Project Name is required' })}
                  placeholder='Project Name'
                  marginBottom='20px'
                  type='text'
                  backgroundColor='gray.800'
                  color='gray.400'
                  fontSize='16px'
                  sx={{ '::placeholder': { color: 'gray.600' } }}
                />
                <ControlledSelect
                  useBasicStyles
                  isMulti={false}
                  control={formMethods.control}
                  name='ResourceIdentifier'
                  id='ResourceIdentifier'
                  options={resourcesQuery.apiResult?.data?.map((resource) => ({ value: resource.ResourceIdentifier, label: resource.Name }))}
                  placeholder='Select a team'
                  label='Project Team'
                  rules={{ required: 'Please enter a team.' }}
                />
                <Flex marginBottom='10px'>
                  <Text>Artist Name</Text>
                  <Text fontSize='12px' color='gray.400' justifySelf='center' alignSelf='center' ml='8px'>(Optional: Start Typing to Select Artist)</Text>
                </Flex>
                <AsyncSelect
                  useBasicStyles
                  cacheOptions
                  loadOptions={promiseOptions}
                  defaultOptions={[]}
                  onChange={(selectedOption) => setSelectedArtist(selectedOption)}
                  isClearable
                  placeholder=''
                  openMenuOnClick={false}
                />
                <Text marginBottom='10px'>Notes</Text>
                <Textarea
                  {...register('Notes')}
                  maxH='180px'
                  color='gray.400'
                  placeholder='Add notes (optional)'
                  height='55px'
                  size='md'
                  borderRadius='8px'
                  borderColor='gray.400'
                  marginBottom='20px'
                  sx={{ '::placeholder': { color: 'gray.600' }, 'color': 'gray.400' }}
                />
                <When condition={!!errors.ProjectName}>
                  <FormErrorMessage mt='10px' gap='10px'>
                    <Image src={WarningIcon} w='11px' h='10px' />
                    <Text pt='5px' textColor='gold.1000'>{errors.ProjectName?.message}</Text>
                  </FormErrorMessage>
                </When>
                <When condition={!!errors.ResourceIdentifier}>
                  <FormErrorMessage mt='10px' gap='10px' mb='20px'>
                    <Image src={WarningIcon} w='11px' h='10px' />
                    <Text pt='5px' textColor='gold.1000'>{errors.ResourceIdentifier?.message}</Text>
                  </FormErrorMessage>
                </When>
                <AsyncButton
                  formState={formState}
                  height='55px'
                  width='50%'
                  onClick={handleSubmit(onSubmit)}
                >
                  <Flex justifyContent='center'>
                    <Img src={CreateProjectIcon} marginRight='10px' />
                    <Text>Create Project</Text>
                  </Flex>
                </AsyncButton>
              </form>
            </FormProvider>
          </FormControl>
        </Flex>
      </QuerySuspense>
    </ModalFrame>
  );
}
