export function InboxIcon(): JSX.Element {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 26 23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        // eslint-disable-next-line max-len
        d='M1 12.1667V17.2333C1 18.7268 1 19.4736 1.29065 20.044C1.54631 20.5457 1.95425 20.9537 2.45603 21.2093C3.02645 21.5 3.7732 21.5 5.26667 21.5H20.7333C22.2268 21.5 22.9736 21.5 23.544 21.2093C24.0457 20.9537 24.4537 20.5457 24.7093 20.044C25 19.4736 25 18.7268 25 17.2333V12.1667M1 12.1667H5.89936C6.55159 12.1667 6.87772 12.1667 7.18461 12.2404C7.45672 12.3057 7.71684 12.4135 7.95543 12.5596C8.22453 12.7245 8.45515 12.9552 8.91635 13.4164L9.08365 13.5836C9.54487 14.0448 9.77547 14.2755 10.0446 14.4404C10.2832 14.5865 10.5433 14.6943 10.8153 14.7596C11.1223 14.8333 11.4484 14.8333 12.1007 14.8333H13.8993C14.5516 14.8333 14.8777 14.8333 15.1847 14.7596C15.4567 14.6943 15.7168 14.5865 15.9555 14.4404C16.2245 14.2755 16.4552 14.0448 16.9164 13.5836L17.0836 13.4164C17.5448 12.9552 17.7755 12.7245 18.0445 12.5596C18.2832 12.4135 18.5433 12.3057 18.8153 12.2404C19.1223 12.1667 19.4484 12.1667 20.1007 12.1667H25M1 12.1667L4.10185 5.27367C4.71669 3.90733 5.02413 3.22416 5.50701 2.7234C5.93388 2.28075 6.45704 1.94248 7.03588 1.73487C7.69069 1.5 8.43984 1.5 9.93815 1.5H16.0619C17.5601 1.5 18.3093 1.5 18.9641 1.73487C19.5429 1.94248 20.0661 2.28075 20.4929 2.7234C20.9759 3.22416 21.2833 3.90733 21.8981 5.27367L25 12.1667'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
    </svg>

  );
}
