import {
  Button,
  Card, CardBody, CardFooter, CardHeader, Image, Text, chakra,
} from '@chakra-ui/react';

import AsyncButton from './form/AsyncButton';

interface IIntegrationCard {
  title: string,
  description: string,
  image: string,
  isDisabled?: boolean,
  onSubmit: () => void,
}

function IntegrationCard({
  title, description, image, onSubmit, isDisabled = false,
}: IIntegrationCard): JSX.Element {
  return (
    <Card
      direction='column'
      align='center'
      justifyContent='center'
      borderRadius='2xl'
      px='60px'
      py='60px'
      w='329px'
      h='370px'
      variant='elevated'
    >
      <CardHeader p='5px'>
        <Image src={image} w='40px' h='40px' />
      </CardHeader>
      <CardBody>
        <Text textStyle='paragraphBold' align='center'>{title}</Text>
        <Text textStyle='paragraphReg' align='center'>{description}</Text>
      </CardBody>
      <CardFooter p='0px' w='85%'>
        <Button
          variant='connect'
          disabled={isDisabled}
          onClick={onSubmit}
        >{isDisabled ? 'Connected' : 'Connect'}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default chakra(IntegrationCard);
