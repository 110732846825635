import {
  Flex, Text, VStack, chakra,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { When } from 'react-if';

interface ILabeledList {
  title?: string;
  items: Record<string, string | number | JSX.Element>,
  isBackground?: boolean,
}

function LabelledList({ title, items, isBackground = true }: ILabeledList): JSX.Element {
  const rows = useMemo(() => Object.entries(items)
    .map(([label, value]) => (
      <Flex direction='row' justify='space-between' key={label}>
        <Text textStyle='paragraphSemiBold' minW='135px'>{label}: </Text>
        <div>{value}</div>
      </Flex>
    )), [items]);

  return (
    <VStack
      align='start'
      spacing='6px'
      borderRadius='14px'
      px='28px'
      py='35px'
    >
      <When condition={!!title}>
        <Text textStyle='heading4' as='h4' color='gray.500' mb='13px'>{title}</Text>
      </When>
      {rows}
    </VStack>
  );
}

export default chakra(LabelledList);
