/* eslint-disable no-template-curly-in-string */
import { startCase } from 'lodash';
import { addMethod, setLocale, string } from 'yup';
import { MessageParams } from 'yup/lib/types';

function getLabel(params: MessageParams): string {
  return startCase(params.label || params.path);
}

setLocale({
  mixed: {
    notType: (field) => `${getLabel(field)} must be a ${field.type}.`,
    required: (field) => `${getLabel(field)} is required.`,
  },
  string: {
    max: 'Must be at most ${max} characters',
    min: 'Must be at least ${min} characters',
    email: 'Please enter a valid email address',
  },
  number: {
    max: 'Must be at most ${max} numbers',
    min: 'Must be at least ${min} numbers',
  },
});

// https://github.com/jquense/yup/issues/312#issuecomment-442854307
addMethod(string, 'phone', function () {
  return this.transform((value: string) => value.replaceAll(' ', ''))
    .matches(/^\+[1-9]\d{10}$/, 'Invalid Phone Number');
});
