import {
  Container, ContainerProps, Flex, Spacer,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { When } from 'react-if';
import { useNetworkState } from 'react-use';
import { StatsigProvider } from 'statsig-react';
import Parser from 'ua-parser-js';

import AuthorizeDisplay from 'components/AuthorizeDisplay';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { OfflineAlert } from 'components/OfflineAlert';
import { SmsOptInAlert } from 'components/SmsOptInAlert';
import { SwitchToChromeAlert } from 'components/SwitchToChromeAlert';
import { TransferProvider } from 'contexts/transfer/TransferProvider';
import { Roles } from 'contexts/user/claims';
import { env } from 'env';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import useIsSpecificRoute from 'hooks/useIsSpecificRoute';
import useUserContext from 'hooks/useUserContext';

interface IPageTemplate {
  children: ReactNode;
}

const parser = new Parser(navigator.userAgent);

const getContainerProps = (isTransfersRoute: boolean, isArchivesRoute: boolean, isBookingRoute: boolean, isSmallScreen: boolean): ContainerProps => {
  if (isTransfersRoute || isArchivesRoute) {
    return {
      as: 'main',
      minW: '350px',
      maxW: '1920px',
      marginTop: isSmallScreen ? '65px' : '93px',
      marginBottom: '50px',
      paddingX: isSmallScreen ? '0px' : '20px',
      flex: '1',
      overflowY: 'auto',
    };
  }
  if (isBookingRoute) {
    return {
      paddingX: '0px',
      marginTop: '22px',
      marginBottom: '44px',
    };
  }
  return {
    paddingX: '0px',
    marginTop: '22px',
    marginBottom: '44px',
  };
};

// Statsig wants a pro-membership to rename environments so for 150 a month we're just going to map them:
// https://docs.statsig.com/guides/using-environments#:~:text=By%20default%2C%20your%20Statsig%20Project,do%20so%20via%20Project%20Settings.
const envStatsigMap = {
  prod: 'production',
  local: 'development',
  preview: 'development',
};

export default function PageTemplate({ children }: IPageTemplate): JSX.Element {
  const {
    user, isStudioMember, isLoggedIn,
  } = useUserContext();
  const { online } = useNetworkState();
  const isSmallScreen = useIsSmallScreen();
  const { isTransfersRoute, isArchivesRoute, isBookingRoute } = useIsSpecificRoute();

  const isBrowserChrome = parser.getBrowser().name === 'Chrome';
  const containerProps = getContainerProps(isTransfersRoute, isArchivesRoute, isBookingRoute, isSmallScreen);

  return (
    <StatsigProvider
      sdkKey='client-KWSRxrBrUzsM19GIC41BBv2WvxvN8XD8yPVGsgJnPSY'
      user={{
        userID: user?.ContactIdentifier,
        email: user?.EmailAddress,
        userAgent: navigator.userAgent,
        custom: {
          isStudioMember,
          isLoggedIn,
          studioName: user?.AccountName,
        },
      }}
      options={{
        environment: {
          tier: envStatsigMap[env.REACT_APP_ENV],
        },
      }}
      waitForInitialization
    >
      <TransferProvider>
        <Flex direction='column' minH='100vh'>
          <When condition={!isBrowserChrome && !isSmallScreen}>
            <SwitchToChromeAlert />
          </When>
          <When condition={!online}>
            <OfflineAlert />
          </When>
          <When condition={isLoggedIn}>
            <AuthorizeDisplay role={Roles.StudioClient}>
              <SmsOptInAlert />
            </AuthorizeDisplay>
            <Header />
          </When>
          <Container {...containerProps}>
            {children}
          </Container>
          <Spacer />
          <Footer />
        </Flex>
      </TransferProvider>
    </StatsigProvider>
  );
}
