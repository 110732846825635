import {
  Button, FormControl, FormLabel, Input, Stack, Text, useToast,
} from '@chakra-ui/react';
import {
  Controller, FormProvider, useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router';

import { ISelectedContactRoles, RoleSelection } from '../components/RoleSelection';
import { useAddContact } from '../hooks/useAddContact';
import useCreateProjectContact, { IAddNewContact } from '../hooks/useCreateProjectContact';
import AsyncButton from 'components/form/AsyncButton';
import LabelInput from 'components/form/LabelInput';
import PhoneInput from 'components/form/PhoneInput';
import { validateEmail, validatePhoneNumber } from 'components/form/validators';

type IAddNewContactForm = IAddNewContact & ISelectedContactRoles;

export function AddNewContactStep(): JSX.Element {
  const { projectIdentifier, contactInfo } = useAddContact();
  const formMethods = useForm<IAddNewContactForm>({
    defaultValues: {
      email: contactInfo?.email || '',
    },
  });
  const {
    handleSubmit, register, formState, control, getValues,
  } = formMethods;

  const navigate = useNavigate();
  const { addNewContact, addProjectContact, addRolesToContact } = useCreateProjectContact({ projectIdentifier: projectIdentifier! });
  const toast = useToast();

  const navigateOverview = (): void => void navigate(`/booking/sessions/${projectIdentifier}/overview`);

  const onSubmit = async (form: IAddNewContactForm): Promise<void> => {
    const {
      email, firstName, lastName, mobilePhone, ...roles
    } = form;
    const newContact = await addNewContact({
      firstName, lastName, email, mobilePhone,
    });
    await addProjectContact(newContact.ContactIdentifier!);
    await addRolesToContact(newContact.ContactIdentifier!, roles);
    toast({
      title: 'Contact added to Project',
      status: 'success',
    });

    navigateOverview();
  };

  return (
    <FormControl>
      <FormProvider {...formMethods}>
        <form>
          <Stack gap='30px'>
            <Text fontSize='18px'> Contact Information</Text>
            <Stack gap='10px'>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: ' First name is required' }}
                render={({ field, fieldState }) => (
                  <LabelInput label='First Name' error={fieldState.error}>
                    <Input {...field} placeholder='First name' isInvalid={!!fieldState.error} />
                  </LabelInput>
                )}
              />
              <Controller
                name='lastName'
                control={control}
                rules={{ required: 'Last name is required' }}
                render={({ field, fieldState }) => (
                  <LabelInput label='Last Name' error={fieldState.error}>
                    <Input {...field} placeholder='Last name' isInvalid={!!fieldState.error} />
                  </LabelInput>
                )}
              />
              <Controller
                name='email'
                control={control}
                rules={{ required: 'Email is required', validate: validateEmail }}
                render={({ field, fieldState }) => (
                  <LabelInput label='Email' error={fieldState.error}>
                    <Input {...field} placeholder='Email' type='email' isInvalid={!!fieldState.error} defaultValue={contactInfo?.email} />
                  </LabelInput>
                )}
              />
              <Stack spacing='0'>
                <FormLabel mb='0'>Phone</FormLabel>
                <Controller
                  name='mobilePhone'
                  control={control}
                  rules={{ validate: validatePhoneNumber }}
                  render={({ field, fieldState }) => <PhoneInput field={field} fieldState={fieldState} />}
                />
              </Stack>
            </Stack>
            <Text fontSize='18px'>Select their Role(s)</Text>
            <RoleSelection
              defaults={{ isReceiver: contactInfo?.isReceiver ?? false, isUploader: contactInfo?.isUploader ?? false }}
              register={register}
              formState={formState}
              getValues={getValues}
            />
            <AsyncButton
              formState={formState}
              type='submit'
              onClick={handleSubmit(onSubmit)}
            >Add
            </AsyncButton>
            <Button onClick={navigateOverview} variant='link'>Cancel</Button>
          </Stack>
        </form>
      </FormProvider>
    </FormControl>
  );
}
