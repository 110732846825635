import { Box, Tab } from '@chakra-ui/react';

export function StudiumTableTab({ icon, children, disableSelectedStyling = false }: {
  icon: JSX.Element;
  children: string | JSX.Element | JSX.Element[];
  disableSelectedStyling?: boolean;
}): JSX.Element {
  return (
    <Tab
      // {...tabProps}
      fontSize='md'
      fontWeight='600'
      lineHeight='1.5em'
      paddingX={['20px', '20px', '30px']}
      paddingY='15px'
      border='2px solid transparent'
      borderRadius='15px'
      color='white'
      gap='8px'
      _selected={disableSelectedStyling ? {} : {
        color: 'gold.1000',
        fill: 'gold.1000',
        borderColor: 'gold.1000',
      }}
      _active={{
        color: 'gold.1000',
        fill: 'gold.1000',
        borderColor: 'gold.1000',
      }}
    >
      <Box
        h='20px'
        w='20px'
      >
        {icon}
      </Box>
      {children}
    </Tab>
  );
}
