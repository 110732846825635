/* eslint-disable max-len */
export function FilesIcon(): JSX.Element {
  return (
    <svg width='100%' height='100%' viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.04545 0.5C0.915784 0.5 0 1.41578 0 2.54545V13.4545C0 14.5843 0.915784 15.5 2.04545 15.5H10.2273C11.357 15.5 12.2727 14.5843 12.2727 13.4545V5.83757C12.2727 5.29508 12.0572 4.77481 11.6736 4.39121L8.38152 1.0991C7.99793 0.715502 7.47764 0.5 6.93518 0.5H2.04545ZM1.36364 2.54545C1.36364 2.1689 1.6689 1.86364 2.04545 1.86364H6.13636V5.27273C6.13636 6.02584 6.74686 6.63636 7.5 6.63636H10.9091V13.4545C10.9091 13.8311 10.6038 14.1364 10.2273 14.1364H2.04545C1.6689 14.1364 1.36364 13.8311 1.36364 13.4545V2.54545ZM10.6267 5.27273L7.5 2.14605V5.27273H10.6267Z'
      />
    </svg>
  );
}
