import { Icon } from '@chakra-ui/react';

export function RequestsIcon(): JSX.Element {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 17 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M5.72081 0.766264C5.8913 0.595782 6.12253 0.5 6.36364 0.5H13.6364C15.1426 0.5 16.3636 1.72105 16.3636 3.22727V5.95455C16.3636 6.45662 15.9566 6.86364 15.4545 6.86364C14.9525 6.86364 14.5455 6.45662 14.5455 5.95455V3.22727C14.5455 2.7252 14.1385 2.31818 13.6364 2.31818H7.27273V6.86364C7.27273 7.36571 6.86573 7.77273 6.36364 7.77273H1.81818V17.7727C1.81818 18.2748 2.2252 18.6818 2.72727 18.6818H7.27273C7.77482 18.6818 8.18182 19.0888 8.18182 19.5909C8.18182 20.093 7.77482 20.5 7.27273 20.5H2.72727C1.22105 20.5 0 19.279 0 17.7727V6.86364C0 6.62253 0.0957819 6.3913 0.266264 6.22081L5.72081 0.766264ZM3.10383 5.95455H5.45455V3.60383L3.10383 5.95455ZM13.8636 18.4545C13.8636 19.0822 13.3549 19.5909 12.7273 19.5909C12.0996 19.5909 11.5909 19.0822 11.5909 18.4545C11.5909 17.8269 12.0996 17.3182 12.7273 17.3182C13.3549 17.3182 13.8636 17.8269 13.8636 18.4545ZM11.0321 11.3766C11.2726 10.9178 11.8937 10.5 12.7273 10.5C13.8936 10.5 14.5455 11.2586 14.5455 11.8636C14.5455 12.4686 13.8936 13.2273 12.7273 13.2273C12.2252 13.2273 11.8182 13.6343 11.8182 14.1364V15.0455C11.8182 15.5475 12.2252 15.9545 12.7273 15.9545C13.2294 15.9545 13.6364 15.5475 13.6364 15.0455V14.9424C15.1196 14.6032 16.3636 13.4519 16.3636 11.8636C16.3636 9.95818 14.5734 8.68182 12.7273 8.68182C11.3242 8.68182 10.0229 9.38591 9.42182 10.5325C9.18864 10.9771 9.36018 11.5265 9.80482 11.7597C10.2495 11.9928 10.7989 11.8214 11.0321 11.3766Z'
        fill='currentColor'
      />
    </svg>

  );
}
