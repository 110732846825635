import { IClaim } from 'types/studio-server';

enum Claims {
  Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
}

export enum Roles {
  StudioMember = 'StudioMember',
  StudioClient = 'StudioClient',
  ProjectCreator = 'ProjectCreator',
}

export function hasRole(claims: IClaim[], role: Roles): boolean {
  return claims.filter((predicate) => predicate.Key === Claims.Role && predicate.Value === role).length > 0;
}
