import {
  Button, Flex, FormLabel as Label, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';

import { AsyncWrapper } from 'components/AsyncWrapper';
import DateComponent from 'components/DateComponent';
import AsyncButton from 'components/form/AsyncButton';
import LabelledList from 'components/LabelledList';
import RecipientCheckBox from 'components/RecipientCheckBox';
import useAxios from 'hooks/useAxios';
import {
  Contacts, Files, Transfers,
} from 'types/api';
import { logError, parseError } from 'utils/error';

export function ForwardTransferModal({
  transfer,
  files,
  isOpen,
  onClose,
}: {
  transfer: Transfers,
  files: Files[],
  isOpen: boolean,
  onClose: () => void,
}): JSX.Element {
  const [recipients] = useAxios<Contacts[]>(`/file-api/contacts/${transfer.Project.ProjectNumber}`);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>([]);

  const toast = useToast();

  async function handleSend(): Promise<void> {
    try {
      if (!selectedRecipients.length) {
        void toast({
          title: 'Cannot Upload',
          description: 'Please select at least one recipient',
          status: 'warning',
        });
        return;
      }
      const selectedFiles = files.length ? files.map((f) => f.FileId) : [];
      await axios.post(`/file-api/transfers/${transfer.TransferId}/copy`, {
        sessionId: transfer.SessionId,
        fileIds: selectedFiles,
        contactIdentifiers: selectedRecipients,
      });
      void toast({
        title: 'Success',
        description: 'Transfer Forward Successfully',
      });
      onClose();
    } catch (err: any) {
      logError(err);
      void toast({
        title: 'Failed to Forward Transfer',
        description: parseError(err).message,
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader height='40px' paddingBottom='none'>
          <Text textStyle='heading3' as='h3' align='left'>Forward</Text>
        </ModalHeader>
        <ModalBody>
          <LabelledList
            title='Transfer Details'
            items={{
              Project: transfer.Project.ProjectName ?? '',
              Uploader: transfer.CreatedBy ?? '',
              Date: <DateComponent date={transfer.DateCreated ?? new Date()} />,
            }}
          />
          <AsyncWrapper requests={[recipients]}>
            <Label>Recipients</Label>
            {recipients.data?.map((recipient) => (
              <RecipientCheckBox
                key={recipient.ContactIdentifier}
                contact={recipient}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
              />
            ))}
          </AsyncWrapper>
        </ModalBody>
        <ModalFooter>
          <Flex direction='row' justifyContent='space-between'>
            <AsyncButton
              spinAfterPromiseResolved={false}
              onClick={handleSend}
              w='200px'
              h='52px'
              variant='solid'
              marginRight='2px'
            >
              Send
            </AsyncButton>
            <Button onClick={onClose} w='200px' variant='white' marginLeft='2px'>Close</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
