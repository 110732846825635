import { Else, If, Then } from 'react-if';
import { Navigate, Route } from 'react-router';

import PageTemplate from '../components/page-template/PageTemplate';
import PrivateRoute from '../components/PrivateRoute';
import useUserContext from '../hooks/useUserContext';
import LoginPage from '../pages/LoginPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ChangePasswordPage from '../pages/security-settings/ChangePasswordPage';
import IntegrationsPage from 'pages/IntegrationsPage';
import LinkAccount from 'pages/login-page/link-account/LinkAccount';
import SubscriptionsPage from 'pages/Subscriptions/SubscriptionsPage';

export default function useAuthRoutes(): JSX.Element {
  const { isLoggedIn } = useUserContext();

  return (
    <>
      <Route
        path='/'
        element={(
          <If condition={!isLoggedIn}>
            <Then>
              <Navigate to='/login' />
            </Then>
            <Else>
              <Navigate to='/transfers/inbox' />
            </Else>
          </If>
        )}
      />
      <Route
        path='/login'
        element={(
          <PageTemplate>
            <LoginPage />
          </PageTemplate>
        )}
      />
      <Route
        path='/login/link-account'
        element={(
          <PageTemplate>
            <LinkAccount />
          </PageTemplate>
      )}
      />
      <Route
        path='reset-password'
        element={(
          <PageTemplate>
            <ResetPasswordPage />
          </PageTemplate>
)}
      />
      <Route element={(
        <PageTemplate>
          <PrivateRoute allowContact />
        </PageTemplate>
      )}
      >
        <Route
          path='/passwordsettings'
          element={<ChangePasswordPage />}
        />
        <Route
          path='/integrations'
          element={<IntegrationsPage />}
        />
        <Route
          path='/transfers/subscriptions'
          element={<SubscriptionsPage />}
        />
      </Route>
    </>
  );
}
