import { HTMLProps, useEffect, useRef } from 'react';

export function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>): JSX.Element {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  const styles = {
    width: '14px',
    height: '14px',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <input
      type='checkbox'
      ref={ref}
      className={`${className} cursor-pointer`}
      style={styles}
      {...rest}
    />
  );
}
