import { Box, Flex } from '@chakra-ui/react';

import Splash from './components/Splash';

export default function LandingPage(): JSX.Element {
  return (
    <Box>
      <Flex flexDir='column' width='100%' alignItems='center'>
        <Splash />
      </Flex>
    </Box>
  );
}
