import { Flex, Heading, Image } from '@chakra-ui/react';

import CreateProjectIcon from 'assets/icons/createproject-black.svg';

export function CreateProjectModalHeader(): JSX.Element {
  return (
    <Flex direction='row'>
      <Image src={CreateProjectIcon} h='33px' mr='8px' />
      <Heading fontSize='22px' fontWeight='700px'>New Project</Heading>
    </Flex>
  );
}
