import {
  InputGroup, InputLeftElement, Stack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import ErrorDisplay from 'components/error/ErrorDisplay';

export function IconInput({
  children, icon, alt, error,
}: { children: ReactNode, error?: FieldError, icon: string, alt?: string }): JSX.Element {
  return (
    <Stack gap='12px'>
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <img src={icon} alt={alt} />
        </InputLeftElement>
        {children}
      </InputGroup>
      <ErrorDisplay error={error} />
    </Stack>
  );
}
