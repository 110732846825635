import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { When } from 'react-if';
import { useWizard } from 'react-use-wizard';

import { BookingWizardTimer } from './BookingWizardTimer';
import EngineerHeaderCard from './EngineerHeaderCard';
import CustomErrorBoundary from 'components/error/CustomErrorBoundary';
import { WizardStep, WizardWrapper } from 'components/wizard/WizardWrapper';
import { useBookingContext } from 'contexts/booking/BookingContext';

export type BookingWizardStep = WizardStep & {
  showEngCard?: boolean;
  header?: string;
  subHeader?: string;
  showHeaders: boolean;
};

export type BookingWizardWrapperProps = {
  steps: BookingWizardStep[];
};

export function BookingWizardWrapper({ steps, children }: React.PropsWithChildren<BookingWizardWrapperProps>): JSX.Element {
  const { activeStep } = useWizard();
  const { resource } = useBookingContext();
  const {
    showEngCard = true, subHeader, header, showHeaders,
  } = steps[activeStep];

  return (
    <Box style={{ maxWidth: '430px', margin: '0 auto' }}>
      <WizardWrapper steps={steps}>
        <CustomErrorBoundary>
          <Flex direction='column'>
            <BookingWizardTimer />
            <When condition={showHeaders && !!header}>
              <Text textAlign='center' mb='25px' fontSize='22px' fontFamily='Poppins' fontWeight='semi-bold'>{header!}</Text>
            </When>
            <When condition={showEngCard}>
              <EngineerHeaderCard {...resource} />
            </When>
            <When condition={showHeaders && !!subHeader}>
              <Box backgroundColor='#0F1118' padding='15px' mt='25px' borderRadius='10px'>
                <Text>{subHeader!}</Text>
              </Box>
            </When>
            {children}
          </Flex>
        </CustomErrorBoundary>
      </WizardWrapper>
    </Box>
  );
}
