import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box, Button, ButtonGroup,
} from '@chakra-ui/react';

export type PaginationParams = {
  take: number;
  skip: number;
  query?: string;
};

type PaginationCallBack = React.Dispatch<React.SetStateAction<PaginationParams>>;

export function Pagination({ pagination, setPagination, data } : { pagination: PaginationParams, setPagination: PaginationCallBack, data: any[] }): JSX.Element {
  if (data?.length === 0) {
    return <></>;
  }
  const noNextPage = data?.length < pagination.take;
  const noPreviousPage = pagination.skip - pagination.take < 0;
  function getNextPage(): void {
    if (!pagination || !setPagination || !data || noNextPage) {
      return;
    }
    setPagination({
      skip: pagination.skip + pagination.take,
      take: pagination.take,
      query: pagination.query,
    });
  }

  function getPreviousPage(): void {
    if (!pagination || !setPagination || noPreviousPage) {
      return;
    }

    setPagination({
      skip: pagination.skip - pagination.take,
      take: pagination.take,
      query: pagination.query,
    });
  }

  return (
    <Box mt='50px'>
      <ButtonGroup
        isAttached
        variant='outline'
        border='2px'
        borderColor='gray.400'
        borderRadius='8px'
        float='right'
      >
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={getPreviousPage}
          isDisabled={noPreviousPage}
          p='17px'
          borderRight='2px'
          borderColor='gray.400'
        >Back
        </Button>
        <Button
          onClick={getNextPage}
          rightIcon={<ChevronRightIcon />}
          p='17px'
          borderLeft='2px'
          borderColor='gray.400'
          isDisabled={noNextPage}
        >Next
        </Button>
      </ButtonGroup>
    </Box>
  );
}
