import InboxIcon from 'assets/icons/inbox.svg';
import TransfersTable from 'pages/transfers/project-overview-page/components/TransfersTable';

export default function InboxTable(): JSX.Element {
  const emptyTableState = {
    message: 'You have no transfers in your inbox',
    icon: InboxIcon,
  };

  return (
    <TransfersTable path='/file-api/transfers/inbox' emptyTableState={emptyTableState} />
  );
}
