import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useRef } from 'react';
import { useAsyncFn, useUnmount } from 'react-use';

export interface IUseAxiosRequest<T = any> {
  data?: T,
  headers?: Record<string, any>,
  loading: boolean,
  error?: Error,
}

export type FetchReturn<TResponse> = () => Promise<{
  data: TResponse,
  headers: Record<string, any>,
}>;

export default function useAxios<TResponse = any, TBody = any>(config: AxiosRequestConfig<TBody> | string, manual = false)
  : [IUseAxiosRequest<TResponse>, FetchReturn<TResponse>] {
  const abortController = useRef(new AbortController());

  const [state, fetch] = useAsyncFn(async () => {
    function createRequestConfig(): AxiosRequestConfig {
      const baseConfig = {
        signal: abortController.current.signal,
      };
      if (typeof config === 'string') {
        return {
          url: config,
          ...baseConfig,
        };
      }
      return { ...baseConfig, ...config };
    }

    const response = await axios<TResponse>(createRequestConfig());
    return {
      data: response.data,
      headers: response.headers,
    };
  }, [config]);

  useUnmount(() => {
    abortController.current.abort('Unmounting');
    abortController.current = new AbortController();
  });

  // // if (state.error) handleError(state.error);
  useEffect(() => {
    if (!manual) void fetch();
  }, [fetch, manual]);

  // if not a manual request, loading is true until request dispatch
  const loading = state.loading === true || (!manual && state.value === undefined && state.error === undefined);

  return [{
    data: state.value?.data,
    headers: state.value?.headers,
    loading,
    error: state.error,
  }, fetch];
}
