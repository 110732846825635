import { PhoneNumberUtil } from 'google-libphonenumber';
import { ValidateResult } from 'react-hook-form';
import validator from 'validator';

const phoneUtil = PhoneNumberUtil.getInstance();

export function validatePhoneNumber(phone?: string): ValidateResult {
  if (!phone) return 'Phone number is required';
  try {
    phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone ?? ''));
    return true;
  } catch (error) {
    return (error as Error).message;
  }
}

export function validateEmail(email: string): ValidateResult {
  return validator.isEmail(email) ? true : 'Invalid email';
}
