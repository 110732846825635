import {
  Flex, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, Link, Text, useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import {
  sendPasswordResetEmail,
} from 'firebase/auth';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { firebaseAuth, formatFireBaseError, isFireBaseError } from 'api/auth';
import RoundedKeyIcon from 'assets/icons/rounded-key.svg';
import WarningIcon from 'assets/icons/warning.png';
import AsyncButton from 'components/form/AsyncButton';

interface IResetPassword {
  email: string;
}

export default function ResetPasswordPage(): JSX.Element {
  const actionType = useSearchParam('actionType');
  const actionTitle = actionType === 'reset' ? 'Forgot Password' : 'Activate Account';
  const actionText = actionType === 'reset' ? 'reset your password' : 'activate your account';

  const formMethods = useForm<IResetPassword>();
  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { errors } = formState;
  const toast = useToast();

  async function onSubmit({ email }: IResetPassword): Promise<void> {
    await axios.post('/file-api/auth/activate', { email });

    try {
      await sendPasswordResetEmail(firebaseAuth, email, { url: window.location.origin });
    } catch (e) {
      if (isFireBaseError(e)) {
        setError('email', { type: 'manual', message: formatFireBaseError(e) });
      } else {
        throw e;
      }
    }

    toast({
      title: 'Success',
      description: `Email sent to '${email}'`,
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  return (
    <Flex width={['320px', '400px']} direction='column' mx='auto' align='center'>
      <Image src={RoundedKeyIcon} h='70px' />
      <Flex direction='column' align='center' mt='35px' mb='50px' gap='25px'>
        <Heading fontSize='24px' fontWeight='600px'>{actionTitle}</Heading>
        <Text fontSize='16px' lineHeight='20px' fontWeight='400'>Enter the email you signed up with and we&apos;ll send an email with instructions to {actionText}.</Text>
        <FormControl isInvalid={!!errors.email?.message}>
          <FormProvider {...formMethods}>
            <form>
              <FormLabel>Email</FormLabel>
              <Input
                autoFocus
                type='email'
                {...register('email', { required: 'Email address cannot be empty' })}
              />
              <FormErrorMessage mt='10px' gap='10px' alignItems='baseline'>
                <Image src={WarningIcon} boxSize='15px' />
                <Text>{errors.email?.message} </Text>
              </FormErrorMessage>
              <AsyncButton
                formState={formState}
                type='submit'
                mt='15px'
                variant='login'
                onClick={handleSubmit(onSubmit)}
                backgroundColor='gold.1000'
                color='black'
                // _hover={{ backgroundColor: 'darkGold.1000', color: 'white' }}
              >
                Submit
              </AsyncButton>
            </form>
          </FormProvider>
        </FormControl>
      </Flex>
      <Link as={RouterLink} to='/login' textDecoration='underline' fontSize='14px'> Back to login </Link>
    </Flex>
  );
}
