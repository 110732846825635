import { Flex, Heading } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';

import { TransferTaskList } from './components/TransferTaskList';
import { ProjectOverview } from '../project-overview-page/components/ProjectOverview';
import { AsyncWrapper } from 'components/AsyncWrapper';
import { NavigationTabs } from 'components/NavigationTabs';
import useAxios from 'hooks/useAxios';
import { Upload } from 'pages/transfers/upload-transfer-pages/components/Upload';
import { TransferRequests } from 'types/api';

export function UploadTransferRequestPage(): JSX.Element {
  const { transferRequestId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = Number(searchParams.get('projectId'));
  const [transferRequest] = useAxios<TransferRequests>(`/file-api/transfer-requests/${transferRequestId}`);

  return (
    <AsyncWrapper requests={[transferRequest]}>
      <Flex direction='column' gap='50px'>
        <NavigationTabs hideSearchBar disableSelectedStyling tabName='requests' />
        <Heading fontWeight='700px' fontSize='20px' lineHeight='31px'>Transfer Request</Heading>
        <ProjectOverview projectId={projectId} />
        <Upload uploadRequest={transferRequest.data} />
        <TransferTaskList />
      </Flex>
    </AsyncWrapper>
  );
}
