import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle(() => ({
  field: {
    height: '55px',
    size: 'lg',
    borderRadius: '8px',
    borderColor: 'gray.400',
    borderWidth: '1px',
    fontSize: '14px',
    focusBorderColor: 'gray.200',
    _placeholder: {
      color: 'gray.400',
    },
  },
}));

export const inputTheme = defineMultiStyleConfig({
  variants: { outline },
  defaultProps: {
    variant: 'outline',
  },
});
