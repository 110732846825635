import { Else, If, Then } from 'react-if';

import EngSubscriptions from './EngSubscriptions';
import SmsOptIn from './SmsOptIn';
import useUserContext from 'hooks/useUserContext';

export default function SubscriptionsPage(): JSX.Element {
  const { isStudioMember } = useUserContext();
  return (
    <If condition={isStudioMember}>
      <Then>
        <EngSubscriptions />
      </Then>
      <Else>
        <SmsOptIn />
      </Else>
    </If>
  );
}
