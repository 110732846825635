import { memoize } from 'lodash';
import db from 'mime-db';
import pathParse from 'path-parse';

const getMime = memoize((extension: string): string => {
  if (!extension) return 'application/octet-stream';
  for (const mime in db) {
    if (db[mime]?.extensions?.includes(extension)) {
      return mime;
    }
  }
  return 'application/octet-stream';
});

export function lookupMimeType(filename: string): string {
  const ext = pathParse(filename).ext.replaceAll('.', '').toLowerCase();
  return getMime(ext);
}
