import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export default function useAlert(): {
  errorToast: (title: string) => void,
  successToast: (title: string) => void,
  warningToast: (title: string) => void,
} {
  const toast = useToast({
    duration: 5000,
    isClosable: true,
  });

  const errorToast = useCallback((title: string) => {
    toast({
      title,
      status: 'error',
    });
  }, [toast]);

  const successToast = useCallback((title: string) => {
    toast({
      title,
      status: 'success',
    });
  }, [toast]);

  const warningToast = useCallback((title: string) => {
    toast({
      title,
      status: 'warning',
    });
  }, [toast]);

  return {
    errorToast,
    successToast,
    warningToast,
  };
}
