import { NestedAbortController } from 'utils/NestedAbortController';

export class StallDetector {
  private lastBytesLoaded = 0;

  private currentBytesLoaded = 0;

  private abortController: NestedAbortController;

  public get isStalled(): boolean {
    return this.abortController.signal.aborted;
  }

  public get signal(): AbortSignal {
    return this.abortController.signal;
  }

  private interval: NodeJS.Timeout;

  constructor(parent: AbortSignal, timeoutMs: number = 15000) {
    this.abortController = new NestedAbortController(parent);

    this.interval = setInterval(() => {
      if (this.currentBytesLoaded === 0) return; // Don't abort if we haven't started yet
      if (this.lastBytesLoaded === this.currentBytesLoaded) {
        console.warn('Download stall detected. Aborting...');
        this.stop();
        this.abortController.abort('Download stall detected. Aborting...');
      } else {
        this.lastBytesLoaded = this.currentBytesLoaded;
      }
    }, timeoutMs);
  }

  public ping(bytesLoaded: number): void {
    if (this.isStalled) return;
    this.currentBytesLoaded += bytesLoaded;
  }

  public stop(): void {
    clearInterval(this.interval);
  }
}
