import { createContext } from 'react';

import { FetchReturn } from 'hooks/useAxios';
import { Files } from 'types/api';

interface IFilesPageContext {
  fileList: Files[];
  fetchFileList: FetchReturn<Files[]>
  isReadOnly: boolean;
  isArchive: boolean;
}

const FileListContext = createContext<IFilesPageContext>({
  isReadOnly: false,
  fileList: [],
  fetchFileList: async () => ({ data: [], headers: {} }),
  isArchive: false,
});

export default FileListContext;
