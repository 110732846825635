import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const line = definePartsStyle(() => ({
  tab: {
    px: '30px',
    fontSize: '14px',
    fontWeight: '700',
    fill: 'white',
    _selected: {
      color: 'gold.1000',
      fill: 'gold.1000',
    },
    pb: '21px',
  },
  tabpanel: {
    padding: '25px 0px',
  },
  tablist: {
    borderColor: 'gray.400',
  },
}));

const softRounded = definePartsStyle(() => ({
  tab: {
    textColor: 'white',
    borderRadius: '30px',
    _selected:
  { backgroundColor: 'darkGold.1000', color: 'white' },
  },
}));

const outline = definePartsStyle(() => ({
  tab: {
    fontSize: 'md',
    fontWeight: '600',
    lineHeight: '1.5em',
    paddingX: ['20px', '20px', '30px'],
    paddingY: '15px',
    border: '2px solid transparent',
    borderRadius: '15px',
    color: 'white',
    gap: '8px',
    _selected: {
      color: 'gold.1000',
      fill: 'gold.1000',
      borderColor: 'gold.1000',
    },
    _active: {
      color: 'gold.1000',
      fill: 'gold.1000',
      borderColor: 'gold.1000',
    },
  },
}));

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    line,
    outline,
    'soft-rounded': softRounded,
  },
  baseStyle: {
    tab: {
      fontFamily: 'Poppins',
    },
  },
});
