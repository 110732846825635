import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton, Image, Input, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import CascadeIcon from 'assets/icons/cascade.svg';

export function SearchBar({ onEnter }: { onEnter: (searchTerm: string) => void }): JSX.Element {
  const { search, pathname } = useLocation();
  const [searchTermInput, setSearchTermInput] = useState<string>(new URLSearchParams(search).get('search') ?? '');

  const initialPathname = useRef<string>(pathname);

  useEffect(() => {
    if (initialPathname.current !== pathname) {
      setSearchTermInput('');
    }
  }, [pathname]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermInput(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = () => {
    onEnter(searchTermInput.trim());
  };

  return (
    <InputGroup gap='2'>
      <InputLeftElement h='100%' pointerEvents='none' transform='none'>
        <Image color='gray.400' w='20px' h='20px' src={CascadeIcon} />
      </InputLeftElement>
      <Input
        h='100%'
        border='1px solid'
        borderColor='gray.500'
        borderRadius='15px'
        type='text'
        focusBorderColor='gold.1000'
        placeholder='Search...'
        _placeholder={{ color: 'gray.400' }}
        onKeyDown={handleKeyPress}
        onChange={handleInputChange}
        value={searchTermInput}
      />
      <IconButton
        _hover={{ backgroundColor: 'gray.600' }} // Add this line to change the hover color
        size='lg'
        color='white'
        backgroundColor='gray.700'
        aria-label='Search'
        icon={<SearchIcon />}
        onClick={onSubmit}
      />
    </InputGroup>
  );
}
