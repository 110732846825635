import { Tag } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { Else, If, Then } from 'react-if';

import ActivityIcon from 'assets/icons/activity.svg';
import ContactDisplayName from 'components/ContactDisplayName';
import DateComponent from 'components/DateComponent';
import StudiumTable from 'components/table/StudiumTable';
import { Downloads } from 'types/api';

function ActionTag(failed: boolean): JSX.Element {
  return (
    <If condition={failed}>
      <Then>
        <Tag colorScheme='red'>Failed Download</Tag>
      </Then>
      <Else>
        <Tag colorScheme='green'> Downloaded</Tag>
      </Else>
    </If>
  );
}

export function ActivitiesTable({ transferId }: { transferId: string | undefined }): JSX.Element {
  const emptyTableState = {
    message: 'You have no activities yet',
    icon: ActivityIcon,
  };
  const columnHelper = createColumnHelper<Downloads>();
  const downloadTablecolumns = [
    columnHelper.display({
      id: 'name',
      header: 'Name',
      cell: ({ row }) => {
        if (row.original.Contact) {
          return ContactDisplayName(row.original.Contact);
        }
        return `${row.original.CreatedBy}`;
      },
    }),
    columnHelper.display({
      id: 'email',
      header: 'Email',
      cell: ({ row }) => {
        if (row.original.Contact) {
          return `${row.original.Contact.Email}`;
        }
        return `${row.original.CreatedBy}`;
      },
    }),
    columnHelper.display({
      id: 'downloaded',
      header: '',
      cell: ({ row }) => ActionTag(row.original.Failed),
    }),
    columnHelper.display({
      id: 'DateCreated',
      header: () => 'Date',
      cell: ({ row }) => (() => (
        <DateComponent date={new Date(row.original.DateCreated)} />
      ))(),
    }),
  ];

  return (
    <StudiumTable
      columns={downloadTablecolumns}
      apiPath={`/file-api/transfers/${transferId}/downloads`}
      emptyTableState={emptyTableState}
      queryKeys={['activities-table']}
    />
  );
}
