import {
  Flex, Image, Text,
} from '@chakra-ui/react';
import { When } from 'react-if';

import WarningIcon from 'assets/icons/warning.svg';

export default function ErrorDisplay<T extends { message?: string }>({ error }: { error?: T }): JSX.Element {
  return (
    <When condition={error?.message}>
      <Flex gap='10px' alignItems='center'>
        <Image w='20px' h='20px' src={WarningIcon} />
        <Text pt='5px' textColor='gold.1000'>{error?.message} </Text>
      </Flex>
    </When>
  );
}
