import humanFormat from 'human-format';
import { ArrayBuffer as SparkArrayBuffer } from 'spark-md5';
import * as SparkMD5 from 'spark-md5';

import { EMPTY_FILE_HASH } from 'pages/transfers/files-page/components/helpers/version';
import { IStudioErrorOptions, StudioError } from 'StudioError';
import { Files } from 'types/api';

export class AudioHashError extends StudioError {
  public constructor(
    public code: string,
    message: string,
    public options?: IStudioErrorOptions,
  ) {
    super(code, message, options);
  }
}

export type FileMetadata = {
  session_id: string;
  transfer_id: string;
  file_id: string;
  file_name: string;
  relative_path: string;
  parent: string;
  size: string;
  file_hash: string | undefined;
};

export type WavMetadata = {
  wav_metadata_format: string;
  wav_metadata_channels: string;
  wav_metadata_sample_rate: string;
  wav_metadata_byte_rate: string;
  wav_metadata_block_align: string;
  wav_metadata_bits_per_sample: string;
  wav_metadata_audio_hash_md5: string;
};

function Uint8ArrayToASCII(fileData: Uint8Array): string {
  let dataString = '';
  for (let i = 0; i < fileData.length; i += 1) {
    dataString += String.fromCharCode(fileData[i]);
  }
  return dataString;
}

function parseChunkHeader(fileBuffer: ArrayBuffer, fileOffset: number): [number, string, number] {
  let offset = fileOffset;
  let ckId = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  while (ckId !== 'data' && ckId !== 'fmt ') {
    if (offset + 1 > fileBuffer.byteLength) {
      throw new AudioHashError('File is missing data or fmt chunk', `Offset: ${offset}`);
    }
    if (offset > 1024) {
      throw new AudioHashError('Couldn\'t find data chunk within 1024 bytes', `Offset: ${offset}`);
    }

    offset += 1;
    ckId = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  }
  offset += 4;
  const ckSize = new DataView(fileBuffer).getUint32(offset, true);
  offset += 4;
  return [offset - fileOffset, ckId, ckSize];
}

function preProcessFileBuffer(fileBuffer: ArrayBuffer): number {
  const fileSize = fileBuffer.byteLength;

  const metadataSize = 12; // 4 bytes for ckID, 4 bytes for ckSize, 4 bytes for WAVEID
  if (fileSize < metadataSize) {
    throw new AudioHashError('File is too small', `Expected at least ${metadataSize} bytes, got ${fileSize} bytes`);
  }

  let offset = 0;

  // first 4 bytes should be 'RIFF' in ascii
  const ckID = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  if (ckID !== 'RIFF') {
    throw new AudioHashError('File chunk ID is incorrect', `Expected 'RIFF', got '${ckID}'`);
  }
  offset += 4;

  // next 4 bytes are chunk size
  const ckSize = new DataView(fileBuffer).getUint32(offset, true);
  if (ckSize !== fileSize - 8) {
    throw new AudioHashError('File chunk size is incorrect', ` Expected ${fileSize - 8}, got ${ckSize}`);
  }
  offset += 4;

  // next 4 bytes are WAVEID, should be 'WAVE' in ascii
  const WAVEID = Uint8ArrayToASCII(new Uint8Array(fileBuffer.slice(offset, offset + 4)));
  if (WAVEID !== 'WAVE') {
    throw new AudioHashError('File WAVE ID is incorrect', `Expected 'WAVE', got '${WAVEID}'`);
  }
  offset += 4;

  return offset;
}

function parseFmt(fileBuffer: ArrayBuffer, fileOffset: number): [number, WavMetadata] {
  let offset = fileOffset;

  // next 2 bytes are format
  const format = new DataView(fileBuffer).getUint16(offset, true);
  if (format !== 1 && format !== 3) {
    throw new AudioHashError('File is not a valid PCM (1) or IEEE Float (3) wav file format.', `Got ${format}`);
  }
  offset += 2;

  const channels = new DataView(fileBuffer).getUint16(offset, true);
  offset += 2;

  const sampleRate = new DataView(fileBuffer).getUint32(offset, true);
  offset += 4;

  const byteRate = new DataView(fileBuffer).getUint32(offset, true);
  offset += 4;

  const blockAlign = new DataView(fileBuffer).getUint16(offset, true);
  offset += 2;

  const bitsPerSample = new DataView(fileBuffer).getUint16(offset, true);
  offset += 2;

  const metadata: WavMetadata = {
    wav_metadata_format: format === 1 ? 'PCM' : 'IEEE Float',
    wav_metadata_channels: channels.toString(),
    wav_metadata_sample_rate: sampleRate.toString(),
    wav_metadata_byte_rate: byteRate.toString(),
    wav_metadata_block_align: blockAlign.toString(),
    wav_metadata_bits_per_sample: bitsPerSample.toString(),
    wav_metadata_audio_hash_md5: '',
  };

  return [offset - fileOffset, metadata];
}

export function b64ToHex(b64: string | null): string | null {
  if (!b64) return null;
  const raw = atob(b64);
  let result = '';
  for (let i = 0; i < raw.length; i += 1) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : `0${hex}`;
  }
  return result.toLowerCase();
}

export async function calculateFileMd5(file: File): Promise<string> {
  console.time(`Calculating md5 ${file.name}`);
  return await new Promise((resolve, reject) => {
    const blobSlice = File.prototype.slice;

    const chunkSize = humanFormat.parse('64 MB');
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      spark.append(e.target!.result as ArrayBuffer); // Append array buffer
      currentChunk += 1;

      if (currentChunk <= chunks) {
        loadNext();
      } else {
        const resolvedSpark = spark.end();
        console.timeEnd(`Calculating md5 ${file.name}`);
        resolve(resolvedSpark);
      }
    };

    fileReader.onerror = () => {
      reject(new Error('Error calculating MD5 for file'));
    };

    function loadNext(): void {
      const start = currentChunk * chunkSize;
      const end = (start + chunkSize) >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();
  });
}

export async function calculateAudioMetadata(file: File): Promise<Partial<WavMetadata>> {
  if (!file.type.startsWith('audio/')) {
    console.log(`File ${file.name} is not an audio file. Skipping audio metadata calculation.`);
    return {};
  }

  try {
    const fileBuffer = await file.arrayBuffer();
    let wavMetadata: Partial<WavMetadata> = {};
    let offset = preProcessFileBuffer(fileBuffer);

    const hash = new SparkArrayBuffer();
    while (offset < fileBuffer.byteLength) {
      const [chunkOffset, ckId, ckSize] = parseChunkHeader(fileBuffer, offset);
      offset += chunkOffset;
      if (ckId === 'fmt ') {
        const [parseFmtOffset, md] = parseFmt(fileBuffer, offset);
        offset += parseFmtOffset;
        wavMetadata = md;
        continue;
      }
      if (ckId === 'data') {
        let localOffset = 0;
        while (localOffset < ckSize) {
          const bytesToRead = Math.min(ckSize - localOffset, 64 * 1024);
          const chunk = new Uint8Array(fileBuffer.slice(offset, offset + bytesToRead));
          hash.append(chunk);
          offset += bytesToRead;
          localOffset += bytesToRead;
        }
        break;
      }
      // non-data chunk, skip it
      offset += ckSize;
    }
    const audioHash = hash.end();

    if (audioHash === EMPTY_FILE_HASH) {
      return wavMetadata;
    }

    wavMetadata.wav_metadata_audio_hash_md5 = audioHash;
    return wavMetadata;
  } catch (e) {
    console.warn(`Failed to calculate audio hash for file ${file.name} - ${file.type}`, e);
  }
  return {};
}

export async function calculateFileMetadata(file: File, transferFile: Files, dirHandle?: FileSystemDirectoryHandle): Promise<FileMetadata> {
  const fileHash = await calculateFileMd5(file);

  const fileMeta = {
    session_id: `${transferFile.SessionId}`,
    transfer_id: `${transferFile.TransferId}`,
    file_id: `${transferFile.FileId}`,
    relative_path: transferFile.RelativePath,
    file_name: file.name,
    parent: dirHandle?.name || '', // todo ??
    size: file.size.toString(),
    file_hash: fileHash,
  };
  return fileMeta;
}
