import {
  Tooltip,
} from '@chakra-ui/react';

import { getDate } from '../utils/formatter';

export default function DateComponent({ date }:{ date?: string | Date }): JSX.Element {
  if (!date) return <>-</>;
  return (
    <Tooltip label={getDate(date, 'Pp')} placement='top'>
      {getDate(date)}
    </Tooltip>
  );
}
