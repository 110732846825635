import { countBy, throttle, uniqBy } from 'lodash';

import { Files } from 'types/api';
import { formatPercent, getFormattedFileSize } from 'utils/formatter';

export enum TransferStatus {
  'pending',
  'inProgress',
  'completed',
  'retrying',
  'failed',
}

export interface ITransferProgress {
  state: TransferStatus;
  percent: number;
  percentText: string;
  progressStatus: string;
  transferredBytes: number;
  isActive: boolean;
}

export class TransferProgress {
  _state: TransferStatus = TransferStatus.pending;

  _fileStatuses: { [Identifier: string]: { file: Files, status: TransferStatus, transferredBytes: number } };

  private _totalBytes: number;

  private _onChange: (progress: TransferProgress) => void;

  private _errorReason: string | undefined;

  get errorReason(): string | undefined {
    return this._errorReason;
  }

  get state(): TransferStatus {
    return this._state;
  }

  get percent(): number {
    if (!this._totalBytes || !this.transferredBytes) return 0;
    return (this.transferredBytes / this._totalBytes) * 100;
  }

  get percentText(): string {
    return `${formatPercent(this.percent)}`;
  }

  private get sizeStatus(): string {
    return `${getFormattedFileSize(this.transferredBytes)} of ${getFormattedFileSize(this._totalBytes)}`;
  }

  get progressStatus(): string {
    const completedFiles = Object.values(this._fileStatuses).filter((file) => file.status === TransferStatus.completed).length;
    return `File ${Math.min(completedFiles + 1, this.files.length)} of ${this.files.length} (${this.sizeStatus})`;
  }

  get transferredBytes(): number {
    return Object.values(this._fileStatuses).reduce((acc, progress) => acc + progress.transferredBytes, 0);
  }

  get isActive(): boolean {
    return this.state !== TransferStatus.completed && this.state !== TransferStatus.failed;
  }

  public static default(): TransferProgress {
    return new TransferProgress([], () => { });
  }

  constructor(
    private files: Files[],
    onChange: (progress: TransferProgress) => void,
  ) {
    this._state = TransferStatus.pending;
    this._totalBytes = files.reduce((acc, file) => acc + Number(file.FileSize), 0);

    const uniqueTransfers = uniqBy(files, 'TransferId');
    if (uniqueTransfers.length !== 1 && files.length) throw new Error('Can only process files from a single transfer at a time');

    const uniqueIds = uniqBy(files, 'FileId');
    if (uniqueIds.length !== files.length) throw new Error('Cannot have duplicate file ids in files array');

    this._fileStatuses = this.files.reduce((acc, file) => ({ ...acc, [file.FileId]: { file, status: TransferStatus.pending, transferredBytes: 0 } }), {});

    this._onChange = throttle(onChange, 250);
  }

  addBytes(blob: Files, bytesLoaded: number): void {
    this.assertNotFailed();
    if (!this.isActive) {
      console.warn('Cannot add bytes to an inactive transfer');
      return;
    }
    const fileStatus = this._fileStatuses[blob.FileId];
    fileStatus.transferredBytes = bytesLoaded;
    fileStatus.status = TransferStatus.inProgress;
    this._state = TransferStatus.inProgress;
    this._onChange(this);
  }

  completeFile(blob: Files): void {
    this.assertNotFailed();
    this.printFileStatusCounts();
    const fileStatus = this._fileStatuses[blob.FileId];
    fileStatus.transferredBytes = Number(blob.FileSize);
    fileStatus.status = TransferStatus.completed;
    this._onChange(this);
  }

  startFile(blob: Files): void {
    this.assertNotFailed();
    this.printFileStatusCounts();
    console.log(`starting ${blob.FilePath}`);
    const fileStatus = this._fileStatuses[blob.FileId];
    fileStatus.status = TransferStatus.inProgress;
    fileStatus.transferredBytes = 0;
    this._state = TransferStatus.inProgress;
    this._onChange(this);
  }

  failFile(blob: Files): void {
    this.printFileStatusCounts();
    const fileStatus = this._fileStatuses[blob.FileId];
    fileStatus.status = TransferStatus.failed;
    this._state = TransferStatus.failed;
    fileStatus.transferredBytes = 0;
    this._onChange(this);
  }

  retryFile(blob: Files): void {
    this.assertNotFailed();
    this.printFileStatusCounts();
    const fileStatus = this._fileStatuses[blob.FileId];
    fileStatus.status = TransferStatus.retrying;
    fileStatus.transferredBytes = 0;
    this._onChange(this);
  }

  completeTransfer(): void {
    this.assertNotFailed();
    this.printFileStatusCounts();
    const allFilesCompleted = Object.values(this._fileStatuses).every((file) => file.status === TransferStatus.completed);
    if (!allFilesCompleted) throw new Error('Cannot complete transfer until all files are completed');
    if (this.transferredBytes !== this._totalBytes) throw new Error('Cannot fail transfer until all bytes are transferred');
    this._state = TransferStatus.completed;
    this._onChange(this);
  }

  failTransfer(reason: string): void {
    if (this._state === TransferStatus.failed) return;
    this.printFileStatusCounts();
    this._state = TransferStatus.failed;
    this._errorReason = reason;
    this._onChange(this);
  }

  private assertNotFailed(): void {
    if (this._state === TransferStatus.failed) throw new Error('Cannot modify a failed transfer');
  }

  private printFileStatusCounts(): void {
    const statusCounts = countBy(Object.values(this._fileStatuses), 'status');
    const statusCountsStr = Object.entries(statusCounts)
      .map(([statusId, count]) => `${TransferStatus[Number(statusId)]}: ${count}`)
      .join(', ');

    console.log('File Status:', statusCountsStr);
  }

  toObject(): ITransferProgress {
    return {
      state: this.state,
      percent: this.percent,
      percentText: this.percentText,
      progressStatus: this.progressStatus,
      transferredBytes: this.transferredBytes,
      isActive: this.isActive,
    };
  }
}
