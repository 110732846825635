import {
  Avatar, AvatarGroup, Flex, Img, Tooltip,
} from '@chakra-ui/react';
import { type Row, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import Requests from 'assets/icons/requests.svg';
import UploadArrowIcon from 'assets/icons/upload-arrow-icon.svg';
import ButtonWithTooltip from 'components/ButtonWithTooltip';
import DateComponent from 'components/DateComponent';
import ProjectName from 'components/ProjectName';
import {
  ArtistHeader, DateHeader, FromHeader, ProjectHeader, RecipientsHeader, SizeHeader, TeamHeader, TransferHeader,
} from 'components/table/headers';
import StudiumTable from 'components/table/StudiumTable';
import useUserContext from 'hooks/useUserContext';
import { TransferRequestCTRow } from 'types/api';
import { filesSizeTags } from 'utils/formatter';

const emptyTableState = {
  message: 'You have no requests yet',
  icon: Requests,
};

export function TransferRequestList(): JSX.Element {
  const navigate = useNavigate();
  const { isStudioClient } = useUserContext();

  const columnHelper = createColumnHelper<TransferRequestCTRow>();

  const transferRequestColumns = [
    columnHelper.accessor('artist', {
      cell: (info) => info.getValue(),
      header: ArtistHeader,
    }),
    columnHelper.accessor('project', {
      cell: (info) => ProjectName(info.getValue()),
      header: ProjectHeader,
    }),
    columnHelper.display({
      id: 'resource',
      header: TeamHeader,
      cell: ({ row }) => (() => (
        <Tooltip label={row.original.team || 'Unknown'}>
          <Avatar size='sm' name={row.original.team || 'unknown'} />
        </Tooltip>
      ))(),
    }),
    columnHelper.accessor('stats.transferCount', {
      cell: (info) => info.getValue(),
      header: TransferHeader,
    }),
    columnHelper.accessor('stats', {
      cell: (info) => filesSizeTags(info.getValue().filesSize, info.getValue().filesCount),
      header: SizeHeader,
    }),
    columnHelper.display({
      id: 'from',
      header: FromHeader,
      cell: ({ row }) => (() => {
        if (isStudioClient) {
          return row.original.team;
        }
        return row.original.from;
      }
      )(),
    }),
    columnHelper.display({
      id: 'recipients',
      header: RecipientsHeader,
      cell: ({ row }) => (() => (
        <Flex dir='row' gap='2'>
          <Tooltip label={row.original.recipients?.map((m) => m.name).join(', ')} hasArrow arrowSize={15}>
            <AvatarGroup size='sm' max={3}>
              {row.original.recipients.map((recipient) => (
                <Avatar name={recipient.name} borderColor='transparent' key={recipient.name} />
              ))}
            </AvatarGroup>
          </Tooltip>
        </Flex>
      ))(),
    }),
    columnHelper.accessor('date', {
      cell: (info) => (() => (
        <DateComponent date={info.getValue()} />
      ))(),
      header: DateHeader,
    }),
    columnHelper.display({
      id: 'Actions',
      header: '',
      cell: ({ row }) => (() => (
        <ButtonWithTooltip
          onClick={() => navigate(`/transfers/transfer-request/${row.original.request.id}?projectId=${row.original.project.id}`)}
          title='Upload'
          icon={<Img w='20px' h='15px' src={UploadArrowIcon} />}
        />
      ))(),
    }),
  ];

  function onRowClick(row: Row<TransferRequestCTRow>): void {
    navigate(`/transfers/transfer-request/${row.original.request.id}?projectId=${row.original.project.id}`);
  }

  return (
    <StudiumTable
      columns={transferRequestColumns}
      apiPath='/file-api/transfer-requests'
      stateKey='state'
      emptyTableState={emptyTableState}
      handleRowClick={onRowClick}
      queryKeys={['transfer-request-table']}
    />
  );
}
