import {
  Box,
  Button,
  Flex, Image, Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useWizard } from 'react-use-wizard';

import { steps } from '../BookingWizard';
import ExpiredIcon from 'assets/icons/expired-icon.svg';
import { BasicModalHeader, ModalFrame } from 'components/modal-frame/ModalFrame';
import { useBookingContext } from 'contexts/booking/BookingContext';

export interface IBookingExpiredModal {
  isOpen: boolean;
}

export default function BookingExpiredModal({
  isOpen,
}: IBookingExpiredModal): JSX.Element {
  const { goToStep } = useWizard();
  const { setMastersByDate } = useBookingContext();

  const onClick = () => {
    const stepKey = 'schedule-session-step';
    const stepIndex = steps.findIndex((step) => step.key === stepKey);
    if (stepIndex === -1) {
      throw new Error(`Step with key ${stepKey} not found`);
    }

    setMastersByDate(undefined);
    goToStep(stepIndex);
  };

  return (
    <ModalFrame
      header={(
        <BasicModalHeader
          title='Booking Expired'
          icon={ExpiredIcon}
        />
      )}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      hasCloseButton={false}
      isOpen={isOpen}
      onClose={() => {}}
    >
      <Flex direction='column' background='transparent'>
        <Flex direction='row' gap='5px' alignItems='start'>
          <Text mb='25px'>
            Your booking session has expired. Please check the schedule again to book your session.
          </Text>
        </Flex>
        <Button onClick={onClick} maxW='240px'>
          <Text>Check schedule again</Text>
        </Button>
      </Flex>
    </ModalFrame>
  );
}
