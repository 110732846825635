import { Container, Spinner, SpinnerProps } from '@chakra-ui/react';

const defaultSpinnerProps: SpinnerProps = {
  thickness: '4px',
  speed: '0.5s',
  color: 'gold.1000',
  m: 100,
  size: 'xl',
};

type StudiumSpinnerProps = {
  spinnerProps?: SpinnerProps
  hideSpinner?: boolean
};

export default function StudiumSpinner({ spinnerProps = defaultSpinnerProps, hideSpinner = false } : StudiumSpinnerProps): JSX.Element {
  return (
    <Container centerContent>
      <Spinner
        {...spinnerProps}
        hidden={hideSpinner}
      />
    </Container>
  );
}
