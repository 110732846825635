import {
  Avatar,
  Box, Button, Flex, Image, Radio,
  RadioGroup, Text, VStack, useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useWizard } from 'react-use-wizard';

import { studioServerApi } from 'api/studioServerApi';
import type { ContactClientDescriptor, DisplayContactClient } from 'api/studioServerTypes';
import AddIcon from 'assets/icons/addcompany.svg';
import QuerySuspense from 'components/QuerySuspense';
import { useBookingContext } from 'contexts/booking/BookingContext';
import { useApiQuery } from 'hooks/useApiQuery';
import useUserContext from 'hooks/useUserContext';

export default function SelectCompany(): JSX.Element {
  const { setClient, client } = useBookingContext();
  const { user } = useUserContext();
  const { nextStep, goToStep, activeStep } = useWizard();
  const toast = useToast();

  const [selectedCompany, setSelectedCompany] = useState<DisplayContactClient | null>(null);

  const queryKey = ['/api/contacts/{contactIdentifier}/descriptor', user?.ContactIdentifier];
  const queryFn = () => studioServerApi.GET('/api/contacts/{contactIdentifier}/descriptor', {
    params: { path: { contactIdentifier: user!.ContactIdentifier } },
  });
  const companyQuery = useApiQuery({ queryKey, queryFn });

  if (companyQuery.apiResult?.data?.ContactClientDescriptors?.length === 0) {
    void nextStep();
  }

  const handleNext = () => {
    if (selectedCompany) {
      setClient(selectedCompany);
      void goToStep(activeStep + 2);
    } else {
      toast({ description: 'Please select a company.' });
    }
  };

  const handleAddCompany = () => {
    void nextStep();
  };

  return (
    <Flex
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      mt='25px'
    >
      <QuerySuspense queries={[companyQuery.queryResult]}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          maxWidth='350'
        >
          <RadioGroup
            onChange={(value) => {
              const displayContactClient = companyQuery.apiResult?.data?.ContactClientDescriptors?.find((c) => c.DisplayContactClient?.ClientIdentifier === value);
              setSelectedCompany(displayContactClient?.DisplayContactClient || null);
            }}
            value={selectedCompany?.ClientIdentifier}
          >
            <VStack
              spacing='20px'
              align='start'
              mb='25px'
            >
              {companyQuery.apiResult?.data?.ContactClientDescriptors?.map((contactClientDescriptor: ContactClientDescriptor) => {
                const displayContactClient = contactClientDescriptor.DisplayContactClient;
                return (
                  <Box
                    as='label'
                    cursor='pointer'
                    borderWidth='2px'
                    borderRadius='12px'
                    border={selectedCompany?.ClientIdentifier === displayContactClient?.ClientIdentifier ? '1px solid #3182CE' : '1px solid #A0AEC0'}
                    backgroundColor={selectedCompany?.ClientIdentifier === displayContactClient?.ClientIdentifier ? 'blue.500' : 'gray.800'}
                    width='350px'
                    height='80px'
                    px='15px'
                    py='15px'
                    alignItems='center'
                    _hover={{ backgroundColor: 'blue.700' }}
                  >
                    <Flex alignItems='center' gap='15px'>
                      <Avatar
                        name={displayContactClient?.ClientName ?? ''}
                        borderRadius={8}
                      />
                      <Radio
                        key={displayContactClient?.ClientNumber}
                        value={displayContactClient?.ClientIdentifier}
                        onChange={() => setSelectedCompany(displayContactClient)}
                        sx={{ display: 'none' }}
                      />
                      {displayContactClient?.ClientName}
                    </Flex>
                  </Box>
                );
              })}
            </VStack>
          </RadioGroup>
          <Flex direction='column' gap='25px' mb='15px'>
            <Button onClick={handleAddCompany} variant='solidDark' height='80px' width='100%'>
              <Image src={AddIcon} marginRight='2' />
              Add a company
            </Button>
            <Button onClick={handleNext} disabled={!selectedCompany}>Next</Button>
          </Flex>
        </Box>
      </QuerySuspense>
    </Flex>
  );
}
