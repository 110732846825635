import { Else, If, Then } from 'react-if';
import { Navigate, Outlet, Route } from 'react-router';

import { AddContactWizard } from '../pages/booking/sessions/project/AddContactWizard';
import SignUpPage from '../pages/booking/sign-up/SignUpWizardPage';
import PageTemplate from 'components/page-template/PageTemplate';
import PrivateRoute from 'components/PrivateRoute';
import { BookingProvider } from 'contexts/booking/BookingContext';
import useUserContext from 'hooks/useUserContext';
import BookingWizard from 'pages/booking/BookingWizard';
import InvoicePaid from 'pages/booking/InvoicePaid';
import InvoiceUnpaid from 'pages/booking/InvoiceUnpaid';
import LandingPage from 'pages/booking/LandingPage';
import MySterlingPage from 'pages/booking/MySterlingPage';
import SessionPayment from 'pages/booking/SessionPayment';
import ProjectOverview from 'pages/booking/sessions/project/ProjectOverview';
import SignOptionsPage from 'pages/booking/SignOptionsPage';
import SecuritySettingsPage from 'pages/security-settings/SecuritySettingsPage';

export default function useBookingRoutes(): JSX.Element {
  const { isLoggedIn } = useUserContext();

  return (
    <Route
      path='/booking/*'
      element={(
        <PageTemplate>
          <Outlet />
        </PageTemplate>
  )}
    >
      <Route path='' element={<LandingPage />} />
      <Route path='sign-up/' element={<SignUpPage />} />
      <Route
        path='sign-options'
        element={(
          <If condition={isLoggedIn}>
            <Then>
              <Navigate to='/booking/new' />
            </Then>
            <Else>
              <SignOptionsPage />
            </Else>
          </If>
  )}
      />
      <Route element={<PrivateRoute allowContact />}>
        <Route path='invoice-unpaid/*' element={<InvoiceUnpaid />} />
        <Route path='invoice-paid/*' element={<InvoicePaid />} />
        <Route
          path='new/*'
          element={(
            <BookingProvider>
              <BookingWizard />
            </BookingProvider>
  )}
        />
        <Route
          path='payment-settings'
          element={<SessionPayment />}
        />
        <Route
          path='security-settings'
          element={<SecuritySettingsPage />}
        />
        <Route
          path='sessions'
          element={<MySterlingPage />}
        />
        <Route
          path='sessions/:projectIdentifier/overview'
          element={<ProjectOverview />}
        />
        <Route
          path='sessions/:projectIdentifier/add-contact'
          element={<AddContactWizard />}
        />
        <Route
          path='sessions/:tabValue'
          element={<MySterlingPage />}
        />
        <Route
          path='invoices'
          element={<MySterlingPage />}
        />
      </Route>
    </Route>
  );
}
