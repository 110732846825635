import {
  Avatar, Flex, Text, Tooltip,
} from '@chakra-ui/react';

import DateIcon from 'assets/icons/date.svg';
import ReviewIcon from 'assets/icons/review.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AuthorizeDisplay from 'components/AuthorizeDisplay';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview from 'components/Overview';
import { Roles } from 'contexts/user/claims';
import useAxios from 'hooks/useAxios';
import { ArchiveOverviewResponse } from 'types/api';
import { filesSizeTags } from 'utils/formatter';

export function ArchiveOverview({ archiveId }: { archiveId?: string | number }): JSX.Element {
  const [overview] = useAxios<ArchiveOverviewResponse>(`/file-api/Archives/${archiveId}/overview`, !archiveId);
  return (
    <Overview
      header='Archive Overview'
      background='orange.1000'
      before={{
        background: 'orange.600',
      }}
    >
      <AsyncWrapper
        requests={[overview]}
        spinnerProps={{
          color: 'white',
          m: 0,
          size: 'md',
          thickness: '2px',
        }}
        mountChildrenDiscrete
      >
        <InfoSet
          label='Date'
          icon={DateIcon}
          value={overview.data?.date ? <DateComponent date={overview.data.date} /> : undefined}
        />
        <InfoSet
          label='Files'
          icon={SizeIcon}
          value={filesSizeTags(overview.data?.stats.fileSize, overview.data?.stats.fileCount)}
        />
        <AuthorizeDisplay role={Roles.StudioMember}>
          <InfoSet
            label='Created by'
            icon={TeamIcon}
            condition={!!overview.data?.from}
            value={(
              <Flex dir='row' gap='2'>
                <Tooltip label={overview.data?.from?.email} hasArrow arrowSize={15}>
                  <Avatar size='sm' name={overview.data?.from?.name} borderColor='transparent' />
                </Tooltip>
              </Flex>
          )}
          />
        </AuthorizeDisplay>
        <InfoSet
          label='Status'
          icon={ReviewIcon}
          value={(
            <Text as='span' whiteSpace='pre-wrap'>
              <Text as='span'>{overview.data?.status}</Text>
            </Text>
          )}
        />
      </AsyncWrapper>
    </Overview>
  );
}
