import { extendTheme } from '@chakra-ui/react';

import { buttonTheme } from './button';
import { selectTheme } from './select';
import { inputTheme } from './styles/input';
import { switchTheme } from './switch';
import { tableTheme } from './table';
import { tabsTheme } from './tabs';

window.localStorage.removeItem('chakra-ui-color-mode');
export const newTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    red: {
      1000: '#371d25',
    },
    purple: {
      1000: '#2a2547',
    },
    teal: {
      1000: '#1c2e36',
    },
    orange: {
      1000: '#5a4532',
    },
    gold: {
      1000: '#D2A969',
    },
    darkGold: {
      1000: '#6E532A',
    },
  },
  components: {
    Table: tableTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    Input: inputTheme,
    Select: selectTheme,
    Switch: switchTheme,
  },
  fonts: {
    heading: 'Poppins, "Trebuchet MS", Helvetica, sans-serif',
    body: 'Roboto, "Trebuchet MS", Helvetica, sans-serif',
    color: 'white',
  },
  styles: {
    global: {
      body: {
        bg: 'gray.900',
        overflowY: 'scroll',
      },
    },
  },
});
