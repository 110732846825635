import { ProjectOverview } from 'pages/transfers/project-overview-page/components/ProjectOverview';

export function CompactProjectOverview({ projectId }: { projectId?: string | number }): JSX.Element {
  return (
    <ProjectOverview
      projectId={projectId}
      hidden={{
        header: true,
        transferCount: true,
        totalSize: true,
        team: true,
      }}
      overviewProps={{
        background: 'gray.900',
        before: {},
        minH: '90px',
      }}
    />
  );
}
