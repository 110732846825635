import {
  Avatar, AvatarGroup, Flex, FlexProps, Text, TextProps, Tooltip,
} from '@chakra-ui/react';
import { When } from 'react-if';

import ArtistIcon from 'assets/icons/artist.svg';
import DateIcon from 'assets/icons/date.svg';
import ProjectIcon from 'assets/icons/project.svg';
import SizeIcon from 'assets/icons/size.svg';
import TeamIcon from 'assets/icons/team.svg';
import TransferIcon from 'assets/icons/transfer.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import DateComponent from 'components/DateComponent';
import { InfoSet } from 'components/Infoset';
import Overview, { OverviewProps } from 'components/Overview';
import useAxios from 'hooks/useAxios';
import { ProjectOverviewResponse } from 'types/api';
import { filesSizeTags } from 'utils/formatter';

type HiddenInfoSets = {
  header?: boolean,
  projectName?: boolean,
  artist?: boolean,
  team?: boolean,
  date?: boolean,
  transferCount?: boolean,
  totalSize?: boolean,
};

const defaultOverviewProps: OverviewProps = {
  header: 'Project Overview',
  background: 'teal.1000',
  before: {
    background: 'teal.600',
  },
};

const defaultHiddenInfoSets = {
  header: false,
  projectName: false,
  artist: false,
  team: false,
  date: false,
  transferCount: false,
  totalSize: false,
};

export function ProjectOverview({
  projectId,
  overviewProps,
  hidden,
}: {
  projectId?: string | number,
  overviewProps?: OverviewProps,
  hidden?: HiddenInfoSets
}): JSX.Element {
  const [overview] = useAxios<ProjectOverviewResponse>(`/file-api/projects/${projectId}/overview`, !projectId);
  const hide = { ...defaultHiddenInfoSets, ...hidden };

  return (
    <Overview
      {...defaultOverviewProps}
      {...overviewProps}
      hideHeader={hide.header}
    >
      <AsyncWrapper
        requests={[overview]}
        spinnerProps={{
          color: 'white',
          m: 0,
          size: 'md',
          thickness: '2px',
        }}
        mountChildrenDiscrete
      >
        <When condition={!hide.projectName}>
          <InfoSet
            label='Project'
            icon={ProjectIcon}
            value={(
              <Text as='span' whiteSpace='pre-wrap'>
                <Text as='span'>{`${overview.data?.projectNumber}: ${overview.data?.name}`}</Text>
              </Text>
            )}
          />
        </When>
        <When condition={!hide.artist}>
          <InfoSet
            label='Artist'
            icon={ArtistIcon}
            value={(
              <Text
                as='span'
                maxW='240px'
                whiteSpace='nowrap'
                textOverflow='ellipsis'
                overflow='hidden'
              >{overview.data?.artist}
              </Text>
            )}
          />
        </When>
        <When condition={!hide.team}>
          <InfoSet
            label='Team'
            icon={TeamIcon}
            condition={overview.data?.team.name || overview.data?.team.members?.length}
            value={(
              <Flex dir='row' gap='2'>
                <When condition={overview.data?.team.name}>
                  <Tooltip label={overview.data?.team.name} hasArrow arrowSize={15}>
                    <Avatar size='sm' name={overview.data?.team.name} />
                  </Tooltip>
                </When>
                <When condition={overview.data?.team.members?.length}>
                  <Tooltip label={overview.data?.team.members?.map((m) => m.name).join(', ')} hasArrow arrowSize={15}>
                    <AvatarGroup size='sm' max={2}>
                      {overview.data?.team.members.map((member) => (
                        <Avatar name={member.name} borderColor='transparent' key={member.name} />
                      ))}
                    </AvatarGroup>
                  </Tooltip>
                </When>
              </Flex>
            )}
          />
        </When>
        <When condition={!hide.date}>
          <InfoSet
            label='Date'
            icon={DateIcon}
            // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
            value={overview.data?.date ? <DateComponent date={overview.data.date} /> : undefined}
          />
        </When>
        <When condition={!hide.transferCount}>
          <InfoSet
            label='Transfer Count'
            icon={TransferIcon}
            value={overview.data?.stats.transferCount}
          />
        </When>
        <When condition={!hide.totalSize}>
          <InfoSet
            label='Total Size'
            icon={SizeIcon}
            value={
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              overview.data?.stats.sizeTotal !== undefined
              || overview.data?.stats.fileCount !== undefined
                ? filesSizeTags(overview.data?.stats.sizeTotal, overview.data?.stats.fileCount)
                : undefined
            }
          />
        </When>
      </AsyncWrapper>
    </Overview>
  );
}
