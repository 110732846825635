import { createStandaloneToast } from '@chakra-ui/react';
import createClient, { Middleware } from 'openapi-fetch';

import { getAuthHeader } from './auth';
import { env } from 'env';
import { paths } from 'studio-api-clients/studio-server';
import { StudioServerApiError } from 'StudioError';

const { toast } = createStandaloneToast({
  defaultOptions: {
    status: 'error',
    position: 'top',
    isClosable: true,
  },
});

const authMiddleware: Middleware = {
  async onRequest({ request }) {
    request.headers.set('Authorization', await getAuthHeader());
    request.headers.set('X-APP-VERSION', env.REACT_APP_VERSION);
    request.headers.set('X-APP-SHA', env.REACT_APP_SHA);
    return request;
  },
};

const errorMiddleware: Middleware & { method: string } = {
  // store method per req
  method: '',
  async onRequest({ request }) {
    this.method = request.method;
    return request;
  },
  async onResponse({ response }) {
    if (response.status < 400) {
      return response;
    }
    const resBody = await response.json();

    const error = new StudioServerApiError(
      response.status,
      response.url,
      this.method,
      resBody,
    );
    void toast({
      title: response.statusText,
      description: resBody.Message || resBody.message || resBody,
    });
    throw error;
  },
};

const loggerMiddleware: Middleware = {
  onRequest({ request, options }) {
    console.log('onRequest', request, options);
    return request;
  },
};

export const studioServerApi = createClient<paths>({ baseUrl: '/' });

studioServerApi.use(
  // loggerMiddleware,
  authMiddleware,
  errorMiddleware,
);
