import pluralize from 'pluralize';

import { Files } from 'types/api';
import { FileRow, getFileResolution } from 'utils/file';
import { getFormattedFileSize } from 'utils/formatter';

export const generateFileMetadata = (fileRow: FileRow) => {
  const parts = [];

  if (fileRow?.FileChannels) {
    parts.push(`Ch. ${fileRow?.FileChannels}`);
  }

  const resolution = getFileResolution(fileRow, false);
  if (resolution) {
    parts.push(resolution);
  }

  if (fileRow.FileSize) {
    parts.push(getFormattedFileSize(Number(fileRow.FileSize)));
  }

  if (fileRow.FileCount !== '-') {
    const fileCount = `${fileRow.FileCount} ${pluralize('file', Number(fileRow.FileCount) || 0)}`;
    parts.push(fileCount);
  }

  return parts.join(' | ');
};

export const generateFilesMetadata = (fileWithMix: Files) => {
  const parts = [];

  if (fileWithMix?.NumberOfChannels) {
    parts.push(`Ch. ${fileWithMix?.NumberOfChannels}`);
  }

  const resolution = getFileResolution(fileWithMix, false);
  if (resolution) {
    parts.push(resolution);
  }

  if (fileWithMix?.FileSize) {
    parts.push(getFormattedFileSize(Number(fileWithMix?.FileSize)));
  }

  return parts.join(' | ');
};
