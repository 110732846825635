import { Box, Stack } from '@chakra-ui/react';
import {
  GroupBase, Props, Select,
} from 'chakra-react-select';
import { FieldError } from 'react-hook-form';

import CountryIcon from 'assets/icons/country.svg';
import ErrorDisplay from 'components/error/ErrorDisplay';

export default function CountrySelector<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group> & { error?: FieldError }): JSX.Element {
  return (
    <Stack>
      <Select
        {...props}
        useBasicStyles
        placeholder={(
          <Box display='flex' gap='5' pl={1} color='gray.400'>
            <img src={CountryIcon} alt='Country icon' />
            {!props.value && 'Select country'}
          </Box>
                    )}
        isInvalid={!!props.error}
        isSearchable={props.isSearchable}
      />
      <ErrorDisplay error={props.error} />
    </Stack>
  );
}
