import { defer } from 'lodash';

export enum DevOverrideKeys {
  STRIPE = 'DEV__STRIPE',
}

export function getOverride(key: DevOverrideKeys): string | null {
  return localStorage.getItem(key);
}

export function setOverride(key: DevOverrideKeys, value: string | undefined | null): void {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
  defer(() => window.location.reload());
}
