import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { init } from './configureSentry';
import reportWebVitals from './reportWebVitals';

init();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode='dark' />
    <App />
  </React.StrictMode>,
);

reportWebVitals();
