import {
  Avatar,
  Box,
  Checkbox, Flex, Image, Spacer, Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';

import useAlert from 'hooks/useAlert';
import { Subscription } from 'types/api';

export default function ChannelCheckbox({ subscription }: { subscription: Subscription }): JSX.Element {
  const [subscriptionState, setSubscriptionState] = useState<Subscription>(subscription);
  const { errorToast } = useAlert();
  const handleEmailChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newEmailEnabled = event.target.checked;
    setSubscriptionState((prevState) => ({ ...prevState, EmailEnabled: newEmailEnabled }));

    try {
      const response = await axios.post('/file-api/subscriptions/update', {
        subscriptionId: subscriptionState.Id,
        emailEnabled: newEmailEnabled,
      });
      setSubscriptionState(response.data);
    } catch (error) {
      errorToast(`Failed to update: ${subscriptionState.Resource.Name}`);
    }
  };

  const handleWebChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const newWebEnabled = event.target.checked;
    setSubscriptionState((prevState) => ({ ...prevState, WebEnabled: newWebEnabled }));

    try {
      const response = await axios.post('/file-api/subscriptions/update', {
        subscriptionId: subscriptionState.Id,
        webEnabled: newWebEnabled,
      });

      setSubscriptionState(response.data);
    } catch (error) {
      errorToast(`Failed to update: ${subscriptionState.Resource.Name}`);
    }
  };

  return (
    <Box border='solid 1px #4A5568' w='80%' minW='720px' borderRadius='15px' color='white'>

      <Flex direction='row'>

        <Flex align='center' gap='4' margin='20px 20px 20px 40px'>
          <Avatar name={subscriptionState.Resource.Name} borderColor='transparent' key={subscriptionState.Resource.Name} />
          <Text>
            {subscriptionState.Resource.Name}
          </Text>
        </Flex>

        <Spacer />

        <Flex gap='300' marginRight='150px'>
          <Flex dir='row'>
            <Checkbox
              isChecked={subscriptionState.WebEnabled}
              onChange={handleWebChange}
            />
          </Flex>
          <Flex dir='row'>
            <Checkbox
              isChecked={subscriptionState.EmailEnabled}
              onChange={handleEmailChange}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
