import {
  Flex, Heading, Image, Text,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import TwoFactorIcon from 'assets/icons/twofactor.svg';
import useUserContext from 'hooks/useUserContext';

export default function TwoFactorVerified(): JSX.Element {
  const { otpaEnrollmentDate } = useUserContext();

  return (
    <Flex
      flexDirection='column'
      gap='20px'
    >
      <Flex
        alignItems='center'
        gap='15px'
        flexDirection='row'
      >
        <Image
          src={TwoFactorIcon}
          height='25px'
        />
        <Heading>2-Step Verification</Heading>
      </Flex>
      <Text>
        You have successfully enrolled for 2-Step Verification on
        <Text as='span' fontWeight='700'>{format(otpaEnrollmentDate || 0!, 'MMMM d, yyyy')}.</Text>
      </Text>
    </Flex>
  );
}
