import { Container, Spinner } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { studioServerApi } from 'api/studioServerApi';
import StudiumSpinner from 'components/StudiumSpinner';

async function processUnpaidInvoice(): Promise<void> {
  const url = window.location.pathname;
  const segments = url.split('/');
  const projectInvoiceIdentifier = segments[5];

  if (!projectInvoiceIdentifier) {
    throw new Error('Please ensure the URL format is correct.');
  }

  await studioServerApi.PUT('/api/project-invoices/{projectInvoiceIdentifier}/cancellation', { params: { path: { projectInvoiceIdentifier } } });
}

export default function InvoiceUnpaid(): JSX.Element {
  const hasProcessedInvoice = useRef(false);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: processUnpaidInvoice,
    onSuccess: () => {
      navigate('/booking/');
    },
  });

  useEffect(() => {
    if (!hasProcessedInvoice.current) {
      hasProcessedInvoice.current = true;

      mutate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StudiumSpinner />
  );
}
